import React from "react";
import { Link } from "react-router-dom";
import ViewDetailFeature from "./ViewDetailFeature";
import products_Arr from "../components/products/product";
// import  viewDetails from "../../views/ViewDetails";
import { useSelector, useDispatch } from "react-redux";
import Intelligent from "../assets/intelligent.svg";
import Innovative from "../assets/innovative.svg";
import Green from "../assets/icn_green.svg";
import { useParams } from "react-router-dom";

export default (props) => {
  const productfeature_name = props.name;
  const product_name = props.product_name;
  // const image = props.image;

  const customStyles = {
    borderRadius: "15px",
    marginLeft: "20px",
    padding: "15px 0px",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  const productsList = useSelector((state) => state.products.products_list);

  let e = productsList[props.product_type].find(
    (t) => t.title === props.product_name
  );

  return (
    <div className="component mobiletop">
      <div className="viewdetailscomponent row">
        {window.innerWidth <= 767 ? (
          props.isFirst && props.isFirst === true ? (
            <div className="rightpart col-sm-4 col-md-4 col-lg-3 align-items-center">
              <div className="d-flex flex-column align-items-center">
                <h5
                  className="text-blue font-weight-bold font-italic mb-4 mt-2 text-center"
                  style={customStyles}
                >
                  {e !== undefined ? e.product_phrase : ""}
                </h5>
                <img src={e.image} alt="" />
              </div>
            </div>
          ) : (
            ""
          )
        ) : (
          <div className="rightpart col-sm-4 col-md-4 col-lg-3 mt-5 pt-2">
            <div className="d-flex flex-column ">
              <h5
                className="text-blue font-weight-bold font-italic mb-4 mt-2 text-center"
                style={customStyles}
              >
                {e !== undefined ? e.product_phrase : ""}
              </h5>
              <img
                src={e.image}
                alt=""
                className="desktopViewDetail"
                style={{ width: "260px", height: "300px", marginLeft: "25px" }}
              />
            </div>
          </div>
        )}
        <div className="leftpart col-sm-8 col-md-8 col-lg-9">
          {productfeature_name === "Downloads" ? (
            <div className="pt-3"></div>
          ) : productfeature_name === "whyproduct" ? (
            <div
              style={{ width: "73.5vw" }}
              className="d-flex justify-content-around whyProductIcons pt-1 pt-lg-5 pt-md-5 pb-3"
            >
              <span>
                <img src={Innovative} alt="" />
                <p>Innovative</p>
              </span>
              <span>
                <img src={Intelligent} alt="" />
                <p>Intelligent</p>
              </span>
              <span>
                <img src={Green} alt="" />
                <p>Green</p>
              </span>
            </div>
          ) : productfeature_name !== "Technical Data" ? (
            <h3 className="mb-1">{productfeature_name}</h3>
          ) : (
            <h1 className="mt-md-4 pt-md-1"></h1>
          )}
          <ViewDetailFeature
            name={props.name}
            product_type={props.product_type}
            product_name={product_name}
          />
        </div>
      </div>
    </div>
  );
};
