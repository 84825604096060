import React from "react";

// reactstrap components
import {
    Button,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
    Table,
    Label,
    FormGroup,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

function LandingPage() {
    const [firstFocus, setFirstFocus] = React.useState(false);
    const [lastFocus, setLastFocus] = React.useState(false);
    const [iconPills, setIconPills] = React.useState("1");
    const [pills, setPills] = React.useState("1");
    React.useEffect(() => {
        document.body.classList.add("landing-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("landing-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);
    return (
        <>

            <ExamplesNavbar />
            <div className="wrapper">
                <div style={{ marginBottom: '140px' }}></div>
                <div className="section section-about-us top-margin">
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto " md="12" >
                                <h2 style={{ fontWeight: "bold", color: "rgb(47, 65, 146)"}}>Careers and Partnerships</h2>
                                <hr></hr>
                                <Row>
                                    <Col md="12">
                                        <p>Chameleon Colour Systems, a world leader and exporting to over 57 countries, design and manufacture mixing and tinting equipment for the paint and coatings industries.</p>
                                        <p>Since 1992, we have provided innovative and intelligent mixing solutions combined with an unrelenting commitment to reduced ownership costs.</p>
                                        <p>Today, we are positioned to lead with European and Asian manufacturing, a global service and support network and an innovative universal product portfolio.</p>
                                        <hr></hr>
                                    </Col>
                                    <Col md="12">
                                        If you are interested in applying for the role and joining a fast moving dynamic company please send your CV to  careers@chameleon.ie
                                    </Col>


                                </Row>
                               <br/>                               
                                <Row>
                                    <Col md="6">
                                        <p style={{ color: '#2F4192' }}>Share This</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={{ size: 12 }}>
                                        <div className="socialLinks">
                                            <ul>
                                                <li><div style={{
                                                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                                                    border: '1px solid #B1BCEB',
                                                    borderRadius: '7px',
                                                    opacity: '1',
                                                    height: '35px',
                                                    width: '35px',
                                                    position: 'relative'
                                                }}>
                                                    <img
                                                        className="insideDivCenter"
                                                        alt="..."
                                                        src={require("assets/social_link/del.png")}
                                                        height="25px"
                                                    ></img>
                                                </div></li>
                                                <li><div style={{
                                                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                                                    border: '1px solid #B1BCEB',
                                                    borderRadius: '7px',
                                                    opacity: '1',
                                                    height: '35px',
                                                    width: '35px',
                                                    position: 'relative'
                                                }}>
                                                    <span className="insideDivCenter" style={{ color: '#2F4192', fontSize: '20px', fontWeight: 'bold' }}>D</span>
                                                </div></li>
                                                <li><div style={{
                                                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                                                    border: '1px solid #B1BCEB',
                                                    borderRadius: '7px',
                                                    opacity: '1',
                                                    height: '35px',
                                                    width: '35px',
                                                    position: 'relative'
                                                }}>
                                                    <img
                                                        className="insideDivCenter"
                                                        alt="..."
                                                        src={require("assets/social_link/facebook.svg")}
                                                        height="25px"
                                                    ></img>
                                                </div></li>
                                                <li><div style={{
                                                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                                                    border: '1px solid #B1BCEB',
                                                    borderRadius: '7px',
                                                    opacity: '1',
                                                    height: '35px',
                                                    width: '35px',
                                                    position: 'relative'
                                                }}>
                                                    <img
                                                        className="insideDivCenter"
                                                        alt="..."
                                                        src={require("assets/social_link/google.png")}
                                                        height="25px"
                                                    ></img>
                                                </div></li>
                                                <li><div style={{
                                                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                                                    border: '1px solid #B1BCEB',
                                                    borderRadius: '7px',
                                                    opacity: '1',
                                                    height: '35px',
                                                    width: '35px',
                                                    position: 'relative'
                                                }}>
                                                    <img
                                                        className="insideDivCenter"
                                                        alt="..."
                                                        src={require("assets/social_link/123.png")}
                                                        height="25px"
                                                    ></img>
                                                </div></li>
                                                <li><div style={{
                                                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                                                    border: '1px solid #B1BCEB',
                                                    borderRadius: '7px',
                                                    opacity: '1',
                                                    height: '35px',
                                                    width: '35px',
                                                    position: 'relative'
                                                }}>
                                                    <img
                                                        className="insideDivCenter"
                                                        alt="..."
                                                        src={require("assets/social_link/twiter.png")}
                                                        height="25px"
                                                    ></img>
                                                </div></li>
                                                <li><div style={{
                                                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                                                    border: '1px solid #B1BCEB',
                                                    borderRadius: '7px',
                                                    opacity: '1',
                                                    height: '35px',
                                                    width: '35px',
                                                    position: 'relative'
                                                }}>
                                                    <img
                                                        className="insideDivCenter"
                                                        alt="..."
                                                        src={require("assets/social_link/linkedin.png")}
                                                        height="25px"
                                                    ></img>
                                                </div></li>
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <DefaultFooter />
            </div>

        </>
    );
}

export default LandingPage;
