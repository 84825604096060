import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function ProfilePageHeader() {
  let pageHeader = React.createRef();


  return (
    <>
      <div
        className="page-header clear-filter page-header-small"
        filter-color="blue"
      >
      </div>
    </>
  );
}

export default ProfilePageHeader;
