import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "views/index-sections/Spinner";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import { getCustomerService } from "redux/actions";
import parse from "html-react-parser";

function LandingPage() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  React.useEffect(() => {
    dispatch(getCustomerService());
  }, []);

  const custService = useSelector((state) => state.common.custService[0]);
  const isLoading = useSelector((state) => state.loader.isLoading);

  return (
    <>
      <ExamplesNavbar />
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="customer_service">
          <div className="customersupport">
            <div className="customer_layout">
              <img
                className="bg_customer"
                src={
                  window.innerWidth <= 800
                    ? require("assets/img_customer.png")
                    : require("assets/bg_customer.png")
                }
                alt=""
              />
            </div>

            <div className="row">
              <div className="apps_button col-lg-6">
                <h5 className="mb-md-0 mb-25"> TRY IT OUT </h5>
                <div className="d-flex">
                  <a
                    href="https://apps.apple.com/us/app/chameleon-service-app/id1481044874"
                    target="_blank"
                  >
                    <img src={require("assets/btn_app_store.png")} alt="" />
                  </a>
                  <a
                    href=" https://play.google.com/store/apps/details?id=com.chameleonm2m"
                    target="_blank"
                  >
                    <img src={require("assets/btn_google_play.png")} alt="" />
                  </a>
                </div>
              </div>

              <div className="desktop_app col-lg-6">
                <h5 className="mb-md-0 mb-25">TRY IT OUT</h5>
                <a
                  className="desktop_app_btn"
                  href="http://web.chameleonm2m.com"
                  target="_blank"
                >
                  <img src={require("assets/icn_desktop.svg")} alt="" />
                  <p> Chameleon Desktop App </p>
                </a>
              </div>
            </div>
            <div className="about_customer">
              <h3>{custService?.title}</h3>
              <p>{custService && parse(custService.description)}</p>
            </div>
          </div>
        </div>
      )}
      <DefaultFooter />
    </>
  );
}

export default LandingPage;
