const intialState = {
  current_feature: 0,
};

const getProductDetail = (state, action) => {
  return {
    ...state,
    current_feature: action.payload,
  };
};

const viewDetails = (state = intialState, action) => {
  switch (action.type) {
    case "GET_PRODUCT_DETAILS":
      return getProductDetail(state, action);

    default:
      return state;
  }
};
export default viewDetails;
