import React from "react";
import { useSelector, useDispatch } from "react-redux";
import parse from "html-react-parser";
import { Col, Row } from "reactstrap";
// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import { getAboutUs } from "../redux/actions";
import Spinner from "views/index-sections/Spinner";

function LandingPage() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  React.useEffect(() => {
    dispatch(getAboutUs());
  }, []);

  const aboutus = useSelector((state) => state.common.aboutus);
  const isLoading = useSelector((state) => state.loader.isLoading);

  return (
    <>
      <ExamplesNavbar />
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="about">
          {/* <div className="firstpart">
            <img
              src={require("assets/Innovation Chameleon Pic 1.png")}
              alt=""
            />
            <img src={require("assets/aboutuspic2.png")} alt="" />
          </div> */}

          <div className="secondpart">
            {aboutus.map((t) =>
              t.sno === 1 ? (
                <Row>
                  <Col md="9">
                    <div className="contentsections">
                      <div className="heading">
                        <img src={t.icon} alt="" />
                        <h3> {t.heading} </h3>
                      </div>
                      <h3 className="blue_head"> {t.title}</h3>
                      <div className="futurevisionpoints">
                        {t && parse(t.description)}
                      </div>
                    </div>
                  </Col>
                  <Col md="3">
                    <img className="rightImg" src={t.content_image} alt="" />
                  </Col>
                </Row>
              ) : t.sno === 2 ? (
                <Row>
                  <Col md="9">
                    <div className="contentsections">
                      <div className="heading">
                        <img src={t.icon} alt="" />
                        <h3>{t.heading}</h3>
                      </div>
                      <h3 className="blue_head">{t.title}</h3>
                      <p>{t.description}</p>
                    </div>
                  </Col>
                  <Col md="3">
                    <img className="rightImg" src={t.content_image} alt="" />
                  </Col>
                </Row>
              ) : t.sno === 3 ? (
                <Row>
                  <Col md="9">
                    <span>
                      <div className="contentsections">
                        <div className="heading">
                          <img src={t.icon} alt="" />
                          <h3>Foundation</h3>
                        </div>
                        <h3 className="blue_head">{t.heading} </h3>
                        <p>{t.title}</p>
                      </div>
                      <div className="keypoints">
                        {t && parse(t.description)}
                      </div>
                    </span>
                  </Col>
                  <Col md="3">
                    <img className="rightImg" src={t.content_image} alt="" />
                  </Col>
                </Row>
              ) : (
                ""
              )
            )}
            <div className="aboutfooter">
              <img
                alt="..."
                className="img-fluid"
                src={require("assets/about-us-img/EUSIFP20142020.png")}
                height="200px"
              ></img>
              <img
                alt="..."
                className="img-fluid"
                src={require("assets/about-us-img/ERDF_Col_Landscape.png")}
                height="200px"
              ></img>
            </div>
          </div>
        </div>
      )}
      <DefaultFooter />
    </>
  );
}

export default LandingPage;
