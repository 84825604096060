import React from "react";
import { Link, useHistory } from "react-router-dom";
// import  viewDetails from "../../views/ViewDetails";
import ViewMoreComponent from "./ViewMoreComponent";
import { useSelector, useDispatch } from "react-redux";
import parse from "html-react-parser";
import { getProductsList } from "../redux/actions";
import MediaPlayerVideo from "mediaPlayer";
import { Row, Col } from "reactstrap";
import Button from "reactstrap/lib/Button";

export default (props) => {
  const productfeature = props.name;
  const product_type = props.product_type;
  const productsList = useSelector((state) => state.products.products_list);
  const history = useHistory();

  let e = productsList[product_type].find(
    (t) => t.title === props.product_name
  );

  if (e !== undefined) {
    // ? todo : remove 1==1 condition and apply proper condition

    if (1 == 1) {
      return (
        <ul className="viewDetailsListType pl-0">
          <span>
            {productfeature === "Technical Data" ? (
              <div className="whyproduct whyproductImg techData mt-1">
                <div className="mygrid">{parse(e.technical_data)}</div>
                {e.technical_data_image && (
                  <div className="mygrid">{parse(e.technical_data_image)}</div>
                )}
                {/* {e.technical_data_image ===
                "https://s3-eu-west-1.amazonaws.com/devchameleon/web-product/" ? (
                  ""
                ) : (
                  <span>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={e.technical_data_image}
                      alt=""
                    />
                  </span>
                )} */}
              </div>
            ) : productfeature === "whyproduct" ? (
              <span className="whyproduct firstTab whyproductImg">
                <div>{parse(e.why_choose_product)}</div>
                <div className="rightImage">
                  {e.why_choose_image && parse(e.why_choose_image)}
                </div>
                {/* <img
                    style={{ height: "280px", width: "100%" }}
                    src={e.why_choose_image}
                    alt=""
                  /> */}
              </span>
            ) : productfeature === "Video" ? (
              ""
            ) : (
              ""
            )}
            {productfeature === "Downloads" ? (
              <>
                <div className="row mt-md-1 ">
                  <Col md="7">
                    <span>
                      <h3 className="mb-md-5 mt-md-3 my-2 pt-md-4 video">
                        Video
                      </h3>
                      <MediaPlayerVideo url={e.video} />
                    </span>
                  </Col>
                  <Col md="5">
                    <span className="d-flex flex-column">
                      <h3 className="pl-1 mb-md-5 mt-md-3 pt-md-4 my-2 brochure">
                        Brochures
                      </h3>
                      <span className="download_points">
                        {parse(e.download)}
                      </span>
                    </span>
                    <Row>
                      <Col md="12">
                        <Button className="download_btn">
                          <a
                            href={e.download_link}
                            target="_blank"
                            className="text-white"
                          >
                            <img
                              src={require("assets/icn_download.svg")}
                              alt=""
                            />
                            <span>Download</span>
                          </a>
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </div>
              </>
            ) : (
              ""
            )}
          </span>
        </ul>
      );
    } else {
      return <div className="elsepoint"></div>;
    }
  } else {
    return <div className="elsepoint"></div>;
  }
};
