import React from "react";

import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import ReactPageScroller from "../maincontent";
import FirstComponent from "./FirstComponent";
import Secondspart from "./Secondspart";
import ThirdComponent from "./ThirdComponent";
import FourthComponent from "./FourthComponent";
import Fourthspart from "./Fourthspart";

// import "./index.scss";
import { NavItem, NavLink, Nav } from "reactstrap";
import DefaultFooter from "components/Footers/DefaultFooter";
import SecondComponent from "./SecondComponent";
import { ChevronRight, ChevronLeft } from "react-feather";

class PageContain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: null,
      isScrolling: false,
    };
    this.data = this.props.data;
    if (this.data) {
      this.state = { currentPage: this.data.product_no };
    }
  }

  handlePageChange = (number) => {
    if (this.state.currentPage !== number) {
      this.setState({ currentPage: number, isScrolling: false });
      // this.props.productList(number);

      let body = document.querySelector(".wholepage");

      if (number < 3) {
        body.classList.remove("overflow_back");
        body.classList.add("overflow_check");
      } else {
        body.classList.remove("overflow_check");
        body.classList.add("overflow_back");
      }
      setTimeout(() => {
        this.setState({
          isScrolling: true,
        });
      }, 20);
    }
  };

  handlePageChangeatscroll = (number) => {
    this.setState({ currentPage: number, isScrolling: false });
    // this.props.productList(number);

    let body = document.querySelector(".wholepage");

    if (number < 3) {
      body.classList.remove("overflow_back");
      body.classList.add("overflow_check");
    } else {
      body.classList.remove("overflow_check");
      body.classList.add("overflow_back");
    }
  };

  getPagesNumbers = () => {
    const pageNumbers = [];
    {
      /* // for (let i = 1; i <= 5; i++) { */
    }
    pageNumbers.push(
      <Nav className="justify-content-around flex-nowrap">
        <NavItem>
          <NavLink
            className={
              this.state.currentPage === 0 ? "active" : "d-none navlinkproduct"
            }
            disabled={this.state.isScrolling}
            key={0}
            eventKey={0}
            onClick={() =>
              this.state.isScrolling ? "" : this.handlePageChange(0)
            }
          >
            <ChevronLeft
              onClick={() =>
                this.state.isScrolling
                  ? ""
                  : this.handlePageChange(this.state.currentPage + 3)
              }
            />
            Gyroscopic Mixers
            <ChevronRight
              onClick={() =>
                this.state.isScrolling
                  ? ""
                  : this.handlePageChange(this.state.currentPage + 1)
              }
            />
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={
              this.state.currentPage === 1 ? "active" : "d-none navlinkproduct"
            }
            disabled={this.state.isScrolling}
            key={1}
            eventKey={1}
            onClick={() =>
              this.state.isScrolling ? "" : this.handlePageChange(1)
            }
          >
            <ChevronLeft
              onClick={() =>
                this.state.isScrolling
                  ? ""
                  : this.handlePageChange(this.state.currentPage - 1)
              }
            />
            Vibrational Shakers
            <ChevronRight
              onClick={() =>
                this.state.isScrolling
                  ? ""
                  : this.handlePageChange(this.state.currentPage + 1)
              }
            />
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={
              this.state.currentPage === 2 ? "active" : "d-none navlinkproduct"
            }
            disabled={this.state.isScrolling}
            key={2}
            eventKey={2}
            onClick={() =>
              this.state.isScrolling ? "" : this.handlePageChange(2)
            }
          >
            <ChevronLeft
              onClick={() =>
                this.state.isScrolling
                  ? ""
                  : this.handlePageChange(this.state.currentPage - 1)
              }
            />
            Manual Dispensers
            <ChevronRight
              onClick={() =>
                this.state.isScrolling
                  ? ""
                  : this.handlePageChange(this.state.currentPage + 1)
              }
            />
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={
              this.state.currentPage === 3 ? "active" : "d-none navlinkproduct"
            }
            disabled={this.state.isScrolling}
            key={3}
            eventKey={3}
            onClick={() =>
              this.state.isScrolling ? "" : this.handlePageChange(3)
            }
          >
            <ChevronLeft
              onClick={() =>
                this.state.isScrolling
                  ? ""
                  : this.handlePageChange(this.state.currentPage - 1)
              }
            />
            Legacy Products
            <ChevronRight
              onClick={() =>
                this.state.isScrolling
                  ? ""
                  : this.handlePageChange(this.state.currentPage - 3)
              }
            />
          </NavLink>
        </NavItem>
      </Nav>
    );

    {
      /* // } */
    }
    return [...pageNumbers];
  };

  render() {
    const pagesNumbers = this.getPagesNumbers();
    // const products =

    return (
      <>
        <ExamplesNavbar />
        <div className="wholepage">
          <div>
            <div className="mobile_products">{pagesNumbers}</div>

            <ReactPageScroller
              pageOnChange={this.handlePageChangeatscroll}
              renderAllPagesOnFirstRender={true}
              containerWidth={window.innerWidth * 1}
              containerHeight={
                window.innerHeight < 700
                  ? window.innerHeight * 0.7
                  : window.innerHeight * 0.8
              }
              customPageNumber={this.state.currentPage}
              blockScrollDown={true}
              blockScrollUp={true}
              // onBeforePageScroll={this.onscrollchange}
            >
              <SecondComponent />
              <FourthComponent />
              <FirstComponent />
              <ThirdComponent />
            </ReactPageScroller>

            <div className="scrollarrow">
              <img
                // className={this.state.currentPage < 3 ? "d-block" : "d-none"}
                style={
                  this.state.currentPage > 0
                    ? { visibility: "visible" }
                    : { visibility: "hidden" }
                }
                className="d-block"
                onClick={() =>
                  this.state.isScrolling
                    ? ""
                    : this.handlePageChange(this.state.currentPage - 1)
                }
                alt=""
                src={require("assets/up.svg")}
              />

              <img
                // className={this.state.currentPage > 0 ? "d-block" : "d-none"}

                style={
                  this.state.currentPage < 3
                    ? { visibility: "visible" }
                    : { visibility: "hidden" }
                }
                className="d-block"
                onClick={() =>
                  this.state.isScrolling
                    ? ""
                    : this.handlePageChange(this.state.currentPage + 1)
                }
                src={require("assets/down.svg")}
                alt=""
              />
            </div>
            {/* <div className="mt-5 mb-5 pt-5"> */}
          </div>
          <DefaultFooter />
          {/* {this.state.currentPage === 3 ? <DefaultFooter /> : ""} */}
        </div>
      </>
    );
  }
}

export default PageContain;
// const mapStateToProps = (state) => {
//   return {
//     current_product: state.products.current_product,
//   };
// };

// export default connect(mapStateToProps, {
//   productList,
// })(PageContain);
