const intialState = {
  isLoading: true,
};

const loader = (state = intialState, action) => {
  switch (action.type) {
    case "IS_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };

    default:
      return state;
  }
};
export default loader;
