import React, { useState } from "react";
import {
  CardBody,
  CardText,
  Row,
  Col,
  Card,
  CardDeck,
  Button,
  Table,
  CardTitle,
  CardImg,
  CardFooter,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import { getNewsList } from "../redux/actions";
import Spinner from "views/index-sections/Spinner";
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import titleImg from "../assets/news.png";
import Date from "../assets/date.svg";
import Location from "../assets/location.svg";
import parse from "html-react-parser";
import { ChevronRight } from "react-feather";
import { Link, useHistory } from "react-router-dom";

const News = () => {
  const [active, setActive] = useState([]);
  const history = useHistory();

  const dispatch = useDispatch();

  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  React.useEffect(() => {
    dispatch(getNewsList());
  }, []);

  const options = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: "Australia/Sydney",
    timeZoneName: "short",
  };
  const news = useSelector((state) => state.common.news);
  const isLoading = useSelector((state) => state.loader.isLoading);

  return (
    <>
      <ExamplesNavbar />
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="news">
          <div className="gridCard">
            {news.map((item) => (
              <Card className="pb-3">
                <CardImg
                  top
                  src={item.image}
                  alt="card1"
                  width={20}
                  style={{ maxWidth: "400px" }}
                />
                <CardBody className="news-body">
                  <CardTitle tag="h5" className="mt-0 text-blue">
                    <span className="d-flex justify-content-between myCursor">
                      {item.title}
                      <ChevronRight
                        onClick={() =>
                          history.push("/news-detail", { details: item })
                        }
                      />
                    </span>
                  </CardTitle>
                  <CardText className="fs-13">
                    {item && parse(item.short_description)}
                  </CardText>
                </CardBody>
                <CardFooter>
                  <div className="d-flex flex-column">
                    <span className="d-flex mb-2">
                      <img src={Date} width={12} className="mx-2" />
                      <span className="text-lightShade font-weight-600 fs-12">
                        {item.news_date}
                      </span>
                    </span>
                    <span className="d-flex">
                      <img src={Location} width={12} className="mx-2" />
                      <span className="text-brown font-weight-600 fs-12">
                        {item.location}
                      </span>
                    </span>
                  </div>
                </CardFooter>
              </Card>
            ))}
          </div>
        </div>
      )}
      <DefaultFooter />
    </>
  );
};

export default News;
