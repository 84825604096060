/*

=========================================================
* Now UI Kit React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-react
* Copyright 2020 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-kit-react/blob/master/LICENSE.md)

* Designed by www.invisionapp.com Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/storeconfig/store";
// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/css/cust-style.scss";
import "assets/Poppins/Poppins-Regular.ttf";
import "assets/scss/now-ui-kit.scss?v=1.4.0";
import "assets/demo/demo.css?v=1.4.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.4.0";
// pages for this kit
import Index from "views/Index.js";
import NucleoIcons from "views/NucleoIcons.js";
import LoginPage from "views/examples/LoginPage.js";
import LandingPage from "views/examples/LandingPage.js";
import ProfilePage from "views/examples/ProfilePage.js";
import Home from "views/home";
import About from "views/about";
import Products from "demo/src/PageContain";
import CustomerService from "views/customerService";
import NewsLatter from "views/newsLatter";
import ContactUs from "views/contactUs";
import Imix from "views/imix";
import EasyColor from "views/easyColor";
// import RedDevil from "views/RedDevil";
import Careers from "views/career";
import PageContain from "./demo/src/PageContain";
import ViewDetails from "views/ViewDetails";
import Dummy from "views/dummy";
import Spinner from "views/index-sections/Spinner";
// import Spinner from "reactstrap/lib/Spinner";
import { Divide } from "react-feather";
import News from "views/news";
import NewsDetail from "views/NewsDetail";

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Switch>
          <Route path="/contain" component={PageContain} />
          {/* <Route path="/" render={(props) => <Spinner {...props} />} /> */}
          <Route path="/index" render={(props) => <Index {...props} />} />
          <Route path="/home" render={(props) => <Home {...props} />} />
          <Route path="/about" render={(props) => <About {...props} />} />
          <Route path="/products" render={(props) => <Products {...props} />} />
          <Route path="/news" render={(props) => <News {...props} />} />
          <Route
            path="/news-detail"
            render={(props) => <NewsDetail {...props} />}
          />
          <Route
            path="/viewDetails/:details/:type"
            render={(props) => <ViewDetails {...props} />}
          />
          <Route path="/dummy" render={(props) => <Dummy {...props} />} />
          <Route
            path="/customer-service"
            render={(props) => <CustomerService {...props} />}
          />
          <Route
            path="/newslatter"
            render={(props) => <NewsLatter {...props} />}
          />
          <Route path="/contact" render={(props) => <ContactUs {...props} />} />
          <Route path="/imix" render={(props) => <Imix {...props} />} />
          <Route
            path="/easycolor"
            render={(props) => <EasyColor {...props} />}
          />
          <Route path="/careers" render={(props) => <Careers {...props} />} />

          <Route
            path="/nucleo-icons"
            render={(props) => <NucleoIcons {...props} />}
          />
          <Route
            path="/landing-page"
            render={(props) => <LandingPage {...props} />}
          />
          <Route
            path="/profile-page"
            render={(props) => <ProfilePage {...props} />}
          />
          <Route
            path="/login-page"
            render={(props) => <LoginPage {...props} />}
          />
          <Redirect to="/home" />
          <Redirect from="/" to="/home" />
        </Switch>
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
