import React, { Component } from "react";

import ViewDetailsMobile from "./ViewDetailsMobile";
import ViewDetailDesktop from "./ViewDetailsDesktop";
import ViewDetailsLargeScrn from "./ViewDetailsLargeScrn";
import { useSelector, useDispatch } from "react-redux";
import { getProductsList } from "../redux/actions";
import Spinner from "views/index-sections/Spinner";
import { useParams } from "react-router-dom";

export default (props) => {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     currentPage: null,
  //     isScrolling: false,
  //   };
  // const data = props.location.state;
  // }
  let { details, type } = useParams();
  const data = { product_name: details, product_type: type };
  console.log(data);
  const points = ["whyproduct", "Technical Data", "Downloads"];

  const dispatch = useDispatch();

  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  React.useEffect(() => {
    dispatch(getProductsList());
  }, []);

  const isLoading = useSelector((state) => state.loader.isLoading);

  if (isLoading) return <Spinner />;
  else {
    if (window.innerWidth <= 800) {
      return <ViewDetailsMobile data={data} points={points} />;
    } else if (window.innerWidth > 1600) {
      return <ViewDetailsLargeScrn data={data} points={points} />;
    } else {
      return <ViewDetailDesktop data={data} points={points} />;
    }
  }
};
