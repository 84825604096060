import ExamplesNavbar from "components/Navbars/ExamplesNavbar";
import React from "react";
import { connect } from "react-redux";
import { getProductsList } from "../../redux/actions";
import DesktopProductPage from "./desktopProductPage";
import MobileProductPage from "./mobileProductPage";
import Spinner from "views/index-sections/Spinner";

class PageContain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: null,
      isScrolling: false,
    };
    this.props.getProductsList();
    this.data = this.props.location.state;
    if (this.data) {
      this.state = { currentPage: this.data.product_no };
    }
  }

  componentDidMount() {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }

  render() {
    if (this.props.isLoading) return <Spinner />;
    else {
      if (window.innerWidth <= 800) {
        return <MobileProductPage data={this.data} />;
      } else {
        return <DesktopProductPage data={this.data} />;
      }
    }
  }
}

// export default PageContain;

const mapStateToProps = (state) => {
  return {
    Products_list: state.products.products_list,
    isLoading: state.loader.isLoading,
  };
};

export default connect(mapStateToProps, {
  getProductsList,
})(PageContain);
