import React from "react";
import { useSelector, useDispatch } from "react-redux";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import { ReactComponent as Fax } from "../assets/icn_fax.svg";
import { ReactComponent as Tele } from "../assets/icn_telephone.svg";
import { getContactUs } from "../redux/actions";
import Spinner from "views/index-sections/Spinner";
import { Mail } from "react-feather";

function LandingPage() {
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getContactUs());
  }, []);

  const contactus = useSelector((state) => state.common.contactus[0]);
  const isLoading = useSelector((state) => state.loader.isLoading);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="ourdiv">
          <ExamplesNavbar />
          <div className="contactpage">
            <div className="tel">
              <img src={require("assets/icn_contact.svg")} alt="" />
              <div className="information">
                <h4> {contactus?.title} </h4>
                <p> {contactus?.full_address} </p>
                <span className="numberdetails">
                  <Tele
                    stroke="#2f4192"
                    strokeWidth={0.9}
                    style={{ width: "40px", padding: "3px 10px !important" }}
                  />
                  <p>{contactus?.mobile_number}</p>
                </span>
                <span className="numberdetails">
                  <Fax
                    stroke="#2f4192"
                    fill="#2f4192"
                    style={{ width: "40px", padding: "3px 10px !important" }}
                  />
                  <p>{contactus?.fax_number}</p>
                </span>
              </div>
            </div>
            <div className="email">
              <img src={require("assets/img_contact_2.svg")} alt="" />
              <div className="information">
                <p>{contactus?.description}</p>
                <span className="d-flex align-items-center">
                  <Mail
                    stroke="#2f4192"
                    size={18}
                    style={{ width: "40px", padding: "3px 5px !important" }}
                  />
                  <h5> {contactus?.career_email} </h5>
                </span>
              </div>
            </div>
          </div>

          <DefaultFooter />
        </div>
      )}
    </>
  );
}

export default LandingPage;
