import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactPageScroller from "../demo/maincontent";

import "../demo/src/index.scss";
import { NavLink, Nav, Col } from "reactstrap";
import DefaultFooter from "components/Footers/DefaultFooter";
import ViewDetailsComponents from "./ViewDetailsComponents";
import { CheckCircle, MoreVertical } from "react-feather";
import ViewMoreComponent from "./ViewMoreComponent";
import ExamplesNavbar from "components/Navbars/ExamplesNavbar";

class ViewDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: null,
      isScrolling: false,
    };
    this.data = this.props.data;
  }

  handlePageChange = (val_number) => {
    if (this.state.currentPage !== val_number) {
      this.setState({ currentPage: val_number, isScrolling: false });
      // this.props.product_details(val_number);

      let body = document.querySelector(".viewdetails");
      let j = this.props.points.length;

      if (val_number < j) {
        body.classList.remove("overflow_back");
        body.classList.add("overflow_check");
      } else {
        body.classList.remove("overflow_check");
        body.classList.add("overflow_back");
      }
      setTimeout(() => {
        this.setState({
          isScrolling: true,
        });
      }, 20);
    }
  };

  handlePageChangeOnScroll = (number) => {
    this.setState({ currentPage: number, isScrolling: false });
    // this.props.product_details(number);

    let body = document.querySelector(".viewdetails");
    let j = this.props.points.length;

    if (number < j) {
      body.classList.remove("overflow_back");
      body.classList.add("overflow_check");
    } else {
      body.classList.remove("overflow_check");
      body.classList.add("overflow_back");
      // }
      // setTimeout(() => {
      //   this.setState({
      //     isScrolling: false,
      //   });
      // }, 200);
    }
  };

  getPagesNumbers = () => {
    const pageNumbers = [];
    {
      // for (let i = 1; i <= 5; i++) {}
      //
      pageNumbers.push(
        <Nav className="justify-content-around">
          {this.data &&
            this.props.points.map((point, i) => (
              <NavLink
                className={
                  this.state.currentPage === i ? "active" : "navlinkproduct"
                }
                disabled={this.state.isScrolling}
                key={i}
                eventKey={i}
                onClick={() =>
                  this.state.isScrolling ? "" : this.handlePageChange(i)
                }
              >
                <span className="viewdetailicons">
                  <img
                    src={require(`assets/${point.toLowerCase()}.svg`)}
                    className="iconcolor"
                  />
                  <p>
                    {point === "whyproduct"
                      ? `Why ${this.data.product_name}`
                      : point}
                  </p>
                </span>
              </NavLink>
            ))}
          {/* {this.data.imgurls.length > 0 ? ( */}
          <NavLink
            className={
              this.state.currentPage === 3
                ? "active align-self-center"
                : "navlinkproduct align-self-center"
            }
            disabled={this.state.isScrolling}
            key={3}
            eventKey={3}
            onClick={() =>
              this.state.isScrolling ? "" : this.handlePageChange(3)
            }
          >
            <span className="viewdetailicons">
              <MoreVertical
                size={50}
                stroke={this.state.currentPage === 3 ? "white" : "#2f4192"}
              />
              <p> View More</p>
            </span>
          </NavLink>
          {/* ) : (
            ""
          )} */}
        </Nav>
      );
    }

    return [...pageNumbers];
  };

  getComponent = () => {
    const loops = [];
    {
      for (let i = 0; i < 4; i++) {
        loops.push(
          <ViewDetailsComponents
            name={this.props.points[i]}
            product_name={this.data.product_name}
          />
        );
      }
      return [...loops];
    }
  };

  render() {
    const pagesNumbers = this.getPagesNumbers();
    const loops = this.getComponent();

    return (
      <>
        <ExamplesNavbar />
        <div className="viewdetails mt-5 pt-3">
          <div className="headings">
            <span>
              {this.data.isFromHome ? (
                <Link to="/home">
                  <img src={require("assets/backicon.svg")} alt="" />
                </Link>
              ) : (
                <Link to="/products">
                  <img src={require("assets/backicon.svg")} alt="" />
                </Link>
              )}
            </span>
            <span className="heading_view_details">
              <h4>{this.data.product_name}</h4>
            </span>
          </div>
          {/* <ul> */}
          {/* {this.data.points.map((point) => ( */}
          {
            //     <span>
            //         <img src={require(`assets/${point.toLowerCase()}.svg`)} alt=""/>
            //        {point}
            //     </span>
            // </ul>
          }
          {/* <img src={this.data.imgurl} alt="" /> */}

          <div className="pagination-additional-class viewPage" bsSize="large">
            {pagesNumbers}
          </div>

          <ReactPageScroller
            pageOnChange={this.handlePageChangeOnScroll}
            renderAllPagesOnFirstRender={true}
            containerWidth={window.innerWidth * 1}
            containerHeight={window.innerHeight * 0.89}
            customPageNumber={this.state.currentPage}
            className="ViewScroller"
            blockScrollDown={true}
            blockScrollUp={true}
          >
            <ViewDetailsComponents
              name="whyproduct"
              product_name={this.data.product_name}
              product_type={this.data.product_type}
              // image={this.data.image}
            />
            <ViewDetailsComponents
              name="Technical Data"
              product_name={this.data.product_name}
              product_type={this.data.product_type}
              // image={this.data.image}
            />
            <ViewDetailsComponents
              name="Downloads"
              product_name={this.data.product_name}
              product_type={this.data.product_type}
              // image={this.data.image}
            />
            {/* <ViewDetailsComponents
            name={this.data.points[3]}
            product_name={this.data.product_name}
            product_type={this.data.product_type}
            image={this.data.image}
          /> */}
            {/* {this.data.imgurls.length > 0 ? ( */}
            <ViewMoreComponent
              product_name={this.data.product_name}
              product_type={this.data.product_type}
              // data={this.data}
            />
            {/* ) : (
            <></>
          )} */}
          </ReactPageScroller>
          <div className="scrollarrow">
            <img
              // className={this.state.currentPage < 3 ? "d-block" : "d-none"}

              style={
                this.state.currentPage > 0
                  ? { visibility: "visible" }
                  : { visibility: "hidden" }
              }
              className="d-block"
              onClick={() =>
                this.state.isScrolling
                  ? ""
                  : this.handlePageChange(this.state.currentPage - 1)
              }
              src={require("assets/up.svg")}
              alt=""
            />

            <img
              // className={this.state.currentPage > 0 ? "d-block" : "d-none"}
              style={
                this.state.currentPage < 3
                  ? { visibility: "visible" }
                  : { visibility: "hidden" }
              }
              className="d-block"
              onClick={() =>
                this.state.isScrolling
                  ? ""
                  : this.handlePageChange(this.state.currentPage + 1)
              }
              src={require("assets/down.svg")}
              alt=""
            />
          </div>
          {/* <div className="mt-5 mb-5 pt-5"> */}
          <DefaultFooter />
        </div>
      </>
    );
  }
}

// const mapStateToProps = (state) => {
//   return {
//     current_feature: state.view_Details.current_feature,
//   };
// };

// export default connect(mapStateToProps, {
//   product_details,
// })(ViewDetails);

export default ViewDetails;
