import React from "react";
import { Link } from "react-router-dom";
import { NavLink as RRNavLink } from "react-router-dom";

import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  NavLink,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

function changeCss() {
  console.log("hello");
}

function ExamplesNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [deviceOS, setDeviceOS] = React.useState("");

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  React.useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    // if (/windows phone/i.test(userAgent)) {
    //   setDeviceOS("Windows Phone");
    // }

    // if (/Android/i.test(userAgent)) {
    //   setDeviceOS("Android");
    // }
    if (navigator.userAgent.match(/Android/i)) {
      setDeviceOS("Android");
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    else if (/iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream) {
      setDeviceOS("iOS");
    } else setDeviceOS("unknown");
  }, []);

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top "} color="info" expand="lg">
        <Container className="containerclass">
          <div className="navbar-translate pl-2 mb-1">
            <Link to="/home" id="navbar-brand">
              <img
                alt="..."
                src={require("assets/img/logo.png")}
                className="mr-0 mr-xl-3"
                height="55px"
              ></img>
              <img
                alt="..."
                src={require("assets/img/img_near_logo.jpg")}
                className="d-none d-xl-inline-block"
                height="55px"
              ></img>
            </Link>
            {/* <UncontrolledTooltip target="#navbar-brand">
              
            </UncontrolledTooltip> */}
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span
                className="navbar-toggler-bar top-bar"
                style={{ background: "#2F4192" }}
              ></span>
              <span
                className="navbar-toggler-bar middle-bar"
                style={{ background: "#2F4192" }}
              ></span>
              <span
                className="navbar-toggler-bar bottom-bar"
                style={{ background: "#2F4192" }}
              ></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink
                  to="/home"
                  activeClassName="selected"
                  tag={RRNavLink}
                  onClick={() => changeCss}
                >
                  Home
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  to="/products"
                  activeClassName="selected"
                  tag={RRNavLink}
                >
                  Products
                </NavLink>
              </NavItem>

              {/* <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  href="#pablo"
                  nav
                  onClick={(e) => e.preventDefault()}
                > */}

              {/* <p>Products</p> */}

              {/* </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem to="/products" tag={RRNavLink}>
                    Vibrational Shakers
                  </DropdownItem>
                  <DropdownItem to="/imix" tag={RRNavLink}>
                    Gyroscopic Mixers
                  </DropdownItem>
                  <DropdownItem to="/easycolor" tag={RRNavLink}>
                    Manual Dispensers
                  </DropdownItem>
                  <DropdownItem to="/red-devil" tag={RRNavLink}>
                    Other Products
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}
              <NavItem>
                <NavLink
                  to="/customer-service"
                  activeClassName="selected"
                  tag={RRNavLink}
                >
                  Customer Support
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink to="/about" activeClassName="selected" tag={RRNavLink}>
                  About
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink to="/news" activeClassName="selected" tag={RRNavLink}>
                  News
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink to="/careers" tag={RRNavLink}>
                  Careers
                </NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink
                  to="/contact"
                  activeClassName="selected"
                  tag={RRNavLink}
                >
                  Contact
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  target="_blank"
                  activeClassName="selected"
                  href={
                    deviceOS == "Android"
                      ? "https://play.google.com/store/apps/details?id=com.chameleonm2m"
                      : deviceOS == "iOS"
                      ? "https://apps.apple.com/us/app/chameleon-service-app/id1481044874"
                      : "https://web.chameleonm2m.com/"
                  }
                >
                  Login
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default ExamplesNavbar;
