import React from "react";

// reactstrap components
import {
    Button,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
    Table
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

function LandingPage() {
    const [firstFocus, setFirstFocus] = React.useState(false);
    const [lastFocus, setLastFocus] = React.useState(false);
    const [iconPills, setIconPills] = React.useState("1");
    const [pills, setPills] = React.useState("1");
    React.useEffect(() => {
        document.body.classList.add("landing-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("landing-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);
    return (
        <>

            <ExamplesNavbar />
            <div className="wrapper">
                <div style={{ marginBottom: '140px' }}></div>
                <div className="section section-about-us top-margin">
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto " md="12" >
                                <Card>
                                    <CardHeader>
                                        <Nav role="tablist" tabs>
                                            <NavItem>
                                                <NavLink
                                                    className={iconPills === "1" ? "active" : ""}
                                                    href="#pablo"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setIconPills("1");
                                                    }}
                                                >

                                                    Why iMix?
                      </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={iconPills === "2" ? "active" : ""}
                                                    href="#pablo"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setIconPills("2");
                                                    }}
                                                >

                                                    intelligent
                      </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={iconPills === "3" ? "active" : ""}
                                                    href="#pablo"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setIconPills("3");
                                                    }}
                                                >

                                                    Compact
                      </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={iconPills === "4" ? "active" : ""}
                                                    href="#pablo"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setIconPills("4");
                                                    }}
                                                >

                                                    Versatile
                      </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={iconPills === "5" ? "active" : ""}
                                                    href="#pablo"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setIconPills("5");
                                                    }}
                                                >

                                                    Technical Date
                      </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={iconPills === "6" ? "active" : ""}
                                                    href="#pablo"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setIconPills("6");
                                                    }}
                                                >

                                                    Video
                      </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={iconPills === "7" ? "active" : ""}
                                                    href="#pablo"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setIconPills("7");
                                                    }}
                                                >

                                                    Downloads
                      </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </CardHeader>
                                    <CardBody>
                                        <TabContent
                                            activeTab={"iconPills" + iconPills}
                                        >
                                            <TabPane tabId="iconPills1">
                                                <Row>
                                                    <Col md="12" className="text-center">
                                                        <h3 style={{ color:"#2F4192"}}>iMix: The Competitive Gyroscopic Mixer</h3>
                                                    </Col>
                                                    <Col md="8">
                                                        
                                                        <u style={{ color: '#2F4192', fontWeight: 'bold' }}>Intelligent</u>
                                                        <ul type="square">
                                                            <li>Just Press Start</li>
                                                            <li>Reduced Mixing Times</li>
                                                        </ul>
                                                        <u style={{ color: '#2F4192', fontWeight: 'bold' }}>Compact - Perfect for Point of Sale</u>
                                                        <ul type="square">
                                                            <li>Modern Styling and Small Footprint</li>
                                                            <li>Reduced Service Costs</li>
                                                        </ul>
                                                        <u style={{ color: '#2F4192', fontWeight: 'bold' }}>Versatile</u>
                                                        <ul type="square">
                                                            <li> Mix round, oval, metal and plastic cans</li>
                                                            <li>Mix 4 cans at the same time</li>
                                                        </ul>
                                                        <u style={{ color: '#2F4192', fontWeight: 'bold' }}>Technial Data</u>
                                                        <br/>

                                                        <Button
                                                            style={{ background: "#2F4192", fontWeight: 'bold' }}
                                                            className="mr-1"
                                                        >
                                                            Just Press Start!
                                                        </Button>
                                                    </Col>
                                                    <Col md="4">
                                                        <div className="text-center">
                                                            <img

                                                                alt="..."
                                                                src={require("assets/product_img/imix.jpg")}
                                                                height="250px"
                                                            ></img>
                                                        </div>
                                                        <div className="text-center">
                                                            <Button
                                                                style={{ background: "#1EC213", fontWeight: 'bold' }}
                                                                className="mr-1"
                                                            >
                                                                <img

                                                                    alt="..."
                                                                    src={require("assets/img/download.svg")}
                                                                    height="15px"
                                                                ></img> &nbsp;
                                                            Download Brochure
                                                        </Button>
                                                            <Button

                                                                className="btn-default" outline type="button"
                                                                style={{ color: '#2F4192' }}
                                                            >
                                                                Download All Products Brochure
                                                        </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="iconPills2">
                                                <Row>
                                                    <Col md="8">
                                                        <h5 style={{ color: "#2F4192", fontWeight: 'bold' }}>iMix - The <em>Intelligent </em>Gyroscopic Mixer</h5>
                                                        <span style={{ color: "#A52A2A", fontWeight: 'bold' }}>iMix will recognise your paint can and automatically adjust and select the</span>

                                                        <ul type="square">
                                                            <li>Fastest Mixing Speed (rpm)</li>
                                                            <li>Optimal Shaking Time</li>
                                                            <li>Best Clamping Pressure</li>
                                                            <li>Automatic - pressure changes based on can size.</li>
                                                            <li>Simple Controls</li>                                                          
                                                        </ul>                                                        
                                                    </Col>
                                                    <Col md="4">
                                                        <div className="text-center">
                                                            <img

                                                                alt="..."
                                                                src={require("assets/product_img/imix-tab2.png")}
                                                                height="250px"
                                                            ></img>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="iconPills3">
                                                <Row>
                                                    <Col md="8">
                                                        <h5 style={{ color: "#2F4192", fontWeight: 'bold' }}><font size="6">iMix - The <em>Compact</em> Gyroscopic Mixer</font></h5>                                                        
                                                        <ul type="square">
                                                            <li>Compact but Robust Mixer</li>
                                                            <li>Easy to Service and Repair</li>
                                                            <li>Large Door and Pull Out Tray with Automatic Clamping and Unclamping</li>
                                                            <li>Clear Controls and large LCD display</li>
                                                        </ul>
                                                        <h6 style={{ color: "#A52A2A", fontWeight: 'bold' }}>Easy to Service</h6>
                                                    </Col>
                                                    <Col md="4">
                                                        <div className="text-center">
                                                            <img

                                                                alt="..."
                                                                src={require("assets/product_img/imix-tab3.png")}
                                                                height="250px"
                                                            ></img>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="iconPills4">
                                                <Row>
                                                    <Col md="8">
                                                        <h5 style={{ color: "#2F4192", fontWeight: 'bold' }}><font size="6">iMix - The <em>Versatile</em> Gyroscopic Mixer</font></h5>                                                        
                                                        <ul type="square">
                                                            <li>Large range of multi can size clamp  plate / adaptors.</li>
                                                            <li>Mixes cans from 8.6-42 cms&nbsp;- Metal, Plastic including oval.</li>
                                                            <li>Available in customised colours.</li>
                                                        </ul>
                                                        
                                                    </Col>
                                                    <Col md="4">
                                                        <div className="text-center">
                                                            <img

                                                                alt="..."
                                                                src={require("assets/product_img/imix2.jpg")}
                                                                height="250px"
                                                            ></img>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </TabPane>

                                            <TabPane tabId="iconPills5">
                                                <Row>
                                                    <Col md="12">
                                                        <h5 style={{ color: "#2F4192", fontWeight: 'bold' }}>iMix - Technical Data</h5>
                                                        <Table borderless>
                                                            <thead>
                                                                <tr style={{ background: '#EFF4F8', color: '#2F4192' }}>
                                                                    <th>Product Name</th>
                                                                    <th>iMix gyroscopic mixer</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Special features</td>
                                                                    <td>Mixer selects mixing speed and pressure automatically

</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Market Applications</td>
                                                                    <td>All Paint Shops mixing large volumes of heavy paint cans including 20 litre cans and including high viscosity paints. New modern styling.</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Unique features</td>
                                                                    <td>
                                                                        <ul type="square">
                                                                            <li>Change parameters via frequency inverter • Very low power consumption</li>                                                                            
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mixing action</td>
                                                                    <td>Gyroscopic - with speed selection</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mixing capacity</td>
                                                                    <td>35 kg</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mixing Speeds</td>
                                                                    <td>3 Automatically selected speeds based on can size</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Motor Power</td>
                                                                    <td>1,1 kw with frequency inverter</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mixing range - container dimensions height</td>
                                                                    <td>8.6 - 42 cms</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mixing range - container dimensions diameter</td>
                                                                    <td>38 cms</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mixing range - container types</td>
                                                                    <td>Metal, Plastic including oval</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Clamping system</td>
                                                                    <td>Automatic - pressure changes based on can size</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Display / Controls</td>
                                                                    <td>LCD with panel</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Timer - Minute timer</td>
                                                                    <td>electronic 1-9, 15, 20, 25, 30 minute</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Programmable</td>
                                                                    <td>Yes</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mixing speed and mixing times</td>
                                                                    <td>3 zones</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Clamping pressure</td>
                                                                    <td>3 zones</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mixing Direction - bi directional</td>
                                                                    <td>No</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Softstart and speed (gear) change</td>
                                                                    <td>Yes</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Languages</td>
                                                                    <td>Yes</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Pull out tray</td>
                                                                    <td>Yes</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Door</td>
                                                                    <td>Large Perspex hanging door</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Dimensions H x W x D (cm)</td>
                                                                    <td>101 x 73 x 84</td>
                                                                </tr>

                                                            </tbody>
                                                        </Table>
                                                        <p>Specifications subject to change without notice</p>
                                                    </Col>

                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="iconPills6">
                                                <Row>
                                                    <Col md="12">
                                                        <div style={{ textAlign: "center" }}>
                                                            <u style={{ color: '#2F4192', fontSize: "26px", fontWeight: 'bold' }}>iMix - Product Demonstration Video</u>
                                                        </div>

                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="iconPills7">
                                                <Row>
                                                    <Col md="8">
                                                        <h5 style={{ color: '#2F4192', fontWeight: 'bold' }}>iMix - Downloads</h5>
                                                        <span>
                                                            Please note that you need login details to download User Manuals,<br /> Technical Manuals or Spare Parts Information.
                                                        </span>
                                                        <ul type="square">
                                                            <li>Please click here to request a new user name and password.</li>
                                                            <li>Please click here to request a reminder of your user name and password.</li>
                                                            <li>If you are restricted from downloading files from the internet and would like information sent to you, please contact customerservice@chameleon.ie</li>
                                                        </ul>
                                                    </Col>
                                                    <Col md="4">
                                                        <div className="text-center">
                                                            <img

                                                                alt="..."
                                                                src={require("assets/product_img/imix2.jpg")}
                                                                height="250px"
                                                            ></img>
                                                        </div>
                                                        <div className="text-center">
                                                            <Button
                                                                style={{ background: "#1EC213", fontWeight: 'bold' }}
                                                                className="mr-1"
                                                            >
                                                                <img

                                                                    alt="..."
                                                                    src={require("assets/img/download.svg")}
                                                                    height="15px"
                                                                ></img> &nbsp;
                                                            Download Brochure
                                                        </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12">
                                                        <h5 style={{ color: "#272A37", fontWeight: 'bold' }}>IMPORTANT NOTICE</h5>
                                                        <div style={{ fontSize: "1em !important" }}>
                                                            <p>Access to this Area is restricted to Qualified Personnel who have received written approval from Chameleon Colour Systems.</p>
                                                            <p>Only technicians who have received official training from Chameleon may use information from this area.</p>
                                                            <p>It is strictly forbidden to use, make copies or in any other manner communicate or disclose information from this area to any party who has not received the approval of Chameleon Colour Systems.</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                        </TabContent>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <div className="separator separator-primary"></div>
                        <Row>
                            <Col className="ml-auto mr-auto " md="12" >
                                <Row>
                                    <Col md={{ size: 12 }}>
                                        <div className="socialLinks">
                                            <ul>
                                                <li><div style={{
                                                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                                                    border: '1px solid #B1BCEB',
                                                    borderRadius: '7px',
                                                    opacity: '1',
                                                    height: '35px',
                                                    width: '35px',
                                                    position: 'relative'
                                                }}>
                                                    <img
                                                        className="insideDivCenter"
                                                        alt="..."
                                                        src={require("assets/social_link/del.png")}
                                                        height="25px"
                                                    ></img>
                                                </div></li>
                                                <li><div style={{
                                                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                                                    border: '1px solid #B1BCEB',
                                                    borderRadius: '7px',
                                                    opacity: '1',
                                                    height: '35px',
                                                    width: '35px',
                                                    position: 'relative'
                                                }}>
                                                    <span className="insideDivCenter" style={{ color: '#2F4192', fontSize: '20px', fontWeight: 'bold' }}>D</span>
                                                </div></li>
                                                <li><div style={{
                                                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                                                    border: '1px solid #B1BCEB',
                                                    borderRadius: '7px',
                                                    opacity: '1',
                                                    height: '35px',
                                                    width: '35px',
                                                    position: 'relative'
                                                }}>
                                                    <img
                                                        className="insideDivCenter"
                                                        alt="..."
                                                        src={require("assets/social_link/facebook.svg")}
                                                        height="25px"
                                                    ></img>
                                                </div></li>
                                                <li><div style={{
                                                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                                                    border: '1px solid #B1BCEB',
                                                    borderRadius: '7px',
                                                    opacity: '1',
                                                    height: '35px',
                                                    width: '35px',
                                                    position: 'relative'
                                                }}>
                                                    <img
                                                        className="insideDivCenter"
                                                        alt="..."
                                                        src={require("assets/social_link/google.png")}
                                                        height="25px"
                                                    ></img>
                                                </div></li>
                                                <li><div style={{
                                                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                                                    border: '1px solid #B1BCEB',
                                                    borderRadius: '7px',
                                                    opacity: '1',
                                                    height: '35px',
                                                    width: '35px',
                                                    position: 'relative'
                                                }}>
                                                    <img
                                                        className="insideDivCenter"
                                                        alt="..."
                                                        src={require("assets/social_link/123.png")}
                                                        height="25px"
                                                    ></img>
                                                </div></li>
                                                <li><div style={{
                                                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                                                    border: '1px solid #B1BCEB',
                                                    borderRadius: '7px',
                                                    opacity: '1',
                                                    height: '35px',
                                                    width: '35px',
                                                    position: 'relative'
                                                }}>
                                                    <img
                                                        className="insideDivCenter"
                                                        alt="..."
                                                        src={require("assets/social_link/twiter.png")}
                                                        height="25px"
                                                    ></img>
                                                </div></li>
                                                <li><div style={{
                                                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                                                    border: '1px solid #B1BCEB',
                                                    borderRadius: '7px',
                                                    opacity: '1',
                                                    height: '35px',
                                                    width: '35px',
                                                    position: 'relative'
                                                }}>
                                                    <img
                                                        className="insideDivCenter"
                                                        alt="..."
                                                        src={require("assets/social_link/linkedin.png")}
                                                        height="25px"
                                                    ></img>
                                                </div></li>
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <DefaultFooter />
            </div>

        </>
    );
}

export default LandingPage;
