import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import parse from "html-react-parser";

export default () => {
  const productsList = useSelector(
    (state) => state.products.products_list.Vibrational_Shakers
  );
  const points = ["whyproduct", "Technical Data", "Downloads"];

  return (
    <>
      <div className="component mixers" style={{ paddingLeft: "50px" }}>
        {productsList.map((t) => (
          <div className="product2">
            <img className="mt-2 product_image" src={t.image} alt="" />
            <div
              className="details"
              style={{ height: "320px", position: "relative" }}
            >
              <h3 className="fs-20"> {t.title} </h3>

              <div className="futurevisionpoints pl-0">
                {parse(t.description)}
              </div>

              <Link
                to={{
                  pathname: `/viewDetails/${t.title}/Vibrational_Shakers`,
                  state: {
                    product_name: t.title,
                    points: points,
                    imgurls: productsList,
                    product_type: "Vibrational_Shakers",
                    image: t.image,
                  },
                }}
                className="p-bottom"
                style={{ position: "absolute", bottom: "-20px" }}
              >
                <button className="mt-md-3 mb-3 mb-md-0"> View Details</button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
