import React from "react";

// reactstrap components
import { Button, Container } from "reactstrap";

// core components

function LandingPageHeader() {
  let pageHeader = React.createRef();


  return (
    <>
      <div className="page-header page-header-small">

      </div>
    </>
  );
}

export default LandingPageHeader;
