import { Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import React from "react";
import ReactPlayer from "react-player";
import { videoPlaceholder } from "assets/video_placeholder.png";

const MediaPlayerVideo = (props) => {
  return (
    <div>
      <ReactPlayer
        url={props.url}
        className="react-player-video"
        style={{ paddingLeft: "20px" }}
        width="95%"
        height="250px"
        light="assets/video_placeholder.png"
        playing={false}
        controls={true}
      />
    </div>
  );
};

export default MediaPlayerVideo;
