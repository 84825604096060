const intialState = {
  aboutus: [],
  contactus: [],
  custService: [],
  news: [],
};

const Products = (state = intialState, action) => {
  switch (action.type) {
    case "ABOUT_US":
      return {
        ...state,
        aboutus: action.data,
      };
    case "CONTACT_US":
      return {
        ...state,
        contactus: action.data,
      };
    case "CUST_SERVICE":
      return {
        ...state,
        custService: action.data,
      };
    case "NEWS_LIST":
      return {
        ...state,
        news: action.data,
      };
    default:
      return state;
  }
};
export default Products;
