import React from "react";
import { Link, useHistory } from "react-router-dom";
import ViewDetailFeature from "./ViewDetailFeature";
// import  viewDetails from "../../views/ViewDetails";
import { useSelector, useDispatch } from "react-redux";

export default (props) => {
  const product_name = props.product_name;
  const product_type = props.product_type;

  const history = useHistory();
  const productsList = useSelector((state) => state.products.products_list);

  const moreProducts = productsList[product_type];

  return (
    <div className="component">
      <div className="viewdetailscomponent d-flex justify-content-around mb-4">
        {moreProducts.map((t) =>
          t.title === product_name ? (
            moreProducts.length == 1 ? (
              <h3 className="align-self-center">No More Products</h3>
            ) : (
              ""
            )
          ) : (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <h2 style={{ color: "#2f4192" }}>{t.title}</h2>
              <img src={t.image} alt="" />
              <button
                className="viewDetailbutton mt-1"
                onClick={() =>
                  history.push("/dummy", {
                    product_name: t.title,
                    // points: props.data.points,
                    // imgurls: props.data.imgurls,
                    product_type: props.product_type,
                    // image: props.data.image,
                  })
                }
              >
                {" "}
                View Details
              </button>
            </div>
          )
        )}
      </div>
    </div>
  );
};
