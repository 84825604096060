import React from "react";

import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar";
import ReactPageScroller from "../maincontent";
import FirstComponent from "./FirstComponent";
import Secondspart from "./Secondspart";
import ThirdComponent from "./ThirdComponent";
import FourthComponent from "./FourthComponent";
import Fourthspart from "./Fourthspart";

// import { connect } from "react-redux";
// import { productList } from "../../redux/actions/index";

import "./index.scss";
import Button from "reactstrap/lib/Button";
import {
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";
import DefaultFooter from "components/Footers/DefaultFooter";
import SecondComponent from "./SecondComponent";

class PageContain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: null,
      isScrolling: false,
    };
    this.data = this.props.data;
    if (this.data) {
      this.state = { currentPage: this.data.product_no };
    }
  }

  handlePageChange = (number) => {
    if (this.state.currentPage !== number) {
      this.setState({ currentPage: number, isScrolling: false });
      // this.props.productList(number);

      let body = document.querySelector(".wholepage");

      if (number < 3) {
        body.classList.remove("overflow_back");
        body.classList.add("overflow_check");
      } else {
        body.classList.remove("overflow_check");
        body.classList.add("overflow_back");
      }
      setTimeout(() => {
        this.setState({
          isScrolling: true,
        });
      }, 20);
    }
  };

  handlePageChangeatscroll = (number) => {
    this.setState({ currentPage: number, isScrolling: false });
    // this.props.productList(number);

    let body = document.querySelector(".wholepage");

    if (number < 3) {
      body.classList.remove("overflow_back");
      body.classList.add("overflow_check");
    } else {
      body.classList.remove("overflow_check");
      body.classList.add("overflow_back");
    }
  };

  getPagesNumbers = () => {
    const pageNumbers = [];
    {
      /* // for (let i = 1; i <= 5; i++) { */
    }
    pageNumbers.push(
      <Nav className="justify-content-around flex-nowrap">
        <NavItem>
          <NavLink
            className={
              this.state.currentPage === 0 ? "active" : "navlinkproduct"
            }
            disabled={this.state.isScrolling}
            key={0}
            eventKey={0}
            onClick={() =>
              this.state.isScrolling ? "" : this.handlePageChange(0)
            }
          >
            Gyroscopic Mixers
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={
              this.state.currentPage === 1 ? "active" : "navlinkproduct"
            }
            disabled={this.state.isScrolling}
            key={1}
            eventKey={1}
            onClick={() =>
              this.state.isScrolling ? "" : this.handlePageChange(1)
            }
          >
            Vibrational Shakers
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={
              this.state.currentPage === 2 ? "active" : "navlinkproduct"
            }
            disabled={this.state.isScrolling}
            key={2}
            eventKey={2}
            onClick={() =>
              this.state.isScrolling ? "" : this.handlePageChange(2)
            }
          >
            Manual Dispensers
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={
              this.state.currentPage === 3 ? "active" : "navlinkproduct"
            }
            disabled={this.state.isScrolling}
            key={3}
            eventKey={3}
            onClick={() =>
              this.state.isScrolling ? "" : this.handlePageChange(3)
            }
          >
            Legacy Products
          </NavLink>
        </NavItem>
      </Nav>
    );

    {
      /* // } */
    }
    return [...pageNumbers];
  };

  render() {
    const pagesNumbers = this.getPagesNumbers();
    // const products =

    return (
      <div className="wholepage">
        <ExamplesNavbar />

        <div className="demo-page-contain">
          <div className="pagination-additional-class product" bsSize="large">
            {pagesNumbers}
          </div>

          <ReactPageScroller
            pageOnChange={this.handlePageChangeatscroll}
            renderAllPagesOnFirstRender={true}
            containerWidth={window.innerWidth * 1}
            containerHeight={window.innerHeight * 0.95}
            customPageNumber={this.state.currentPage}
            blockScrollDown={true}
            blockScrollUp={true}
            // onBeforePageScroll={this.onscrollchange}
          >
            <SecondComponent />
            <FourthComponent />
            <FirstComponent />
            <ThirdComponent />
          </ReactPageScroller>

          <div className="scrollarrow">
            <img
              // className={this.state.currentPage < 3 ? "d-block" : "d-none"}
              style={
                this.state.currentPage > 0
                  ? { visibility: "visible" }
                  : { visibility: "hidden" }
              }
              className="d-block"
              onClick={() =>
                this.state.isScrolling
                  ? ""
                  : this.handlePageChange(this.state.currentPage - 1)
              }
              src={require("assets/up.svg")}
              alt=""
            />

            <img
              // className={this.state.currentPage > 0 ? "d-block" : "d-none"}
              style={
                this.state.currentPage < 3
                  ? { visibility: "visible" }
                  : { visibility: "hidden" }
              }
              className="d-block"
              onClick={() =>
                this.state.isScrolling
                  ? ""
                  : this.handlePageChange(this.state.currentPage + 1)
              }
              src={require("assets/down.svg")}
              alt=""
            />
          </div>
          {/* <div className="mt-5 mb-5 pt-5"> */}
        </div>
        <DefaultFooter />
        {/* {this.state.currentPage === 3 ? <DefaultFooter /> : ""} */}
      </div>
      // </div>
    );
  }
}

export default PageContain;
// const mapStateToProps = (state) => {
//   return {
//     current_product: state.products.current_product,
//   };
// };

// export default connect(mapStateToProps, {
//   productList,
// })(PageContain);
