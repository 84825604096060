import { combineReducers } from "redux";
import loader from "./loader/isloader";
import products from "./product/index";
import view_Details from "./viewDetails/reducer";
import common from "./common";

const rootReducer = combineReducers({
  products: products,
  view_Details: view_Details,
  loader: loader,
  common: common,
});

export default rootReducer;
