import React, { useEffect } from "react";
import { Actionloader } from "redux/actions/index";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar";
import LandingPageHeader from "components/Headers/LandingPageHeader";
import DefaultFooter from "components/Footers/DefaultFooter";
import Carousel from "./index-sections/Carousel";
import ProductSlider from "components/productSlider";
import { Link, useHistory } from "react-router-dom";
import store from "redux/storeconfig/store";
import { connect } from "react-redux";
import Spinner from "views/index-sections/Spinner";
import axios from "axios";

const sliderContent = [
  {
    image: require("product_images/iMix.png"),
    label: "Gyroscopic Mixers",
    product_no: 0,
    isFromHome: true,
  },
  {
    image: require("product_images/eShake.png"),
    label: "Vibrational Shakers",
    product_no: 1,
    isFromHome: true,
  },
  {
    image: require("product_images/Easy_Colour.png"),
    label: "Manual Dispensers",
    product_no: 2,
    isFromHome: true,
  },

  {
    image: require("product_images/RGM.png"),
    label: "Legacy Products",
    product_no: 3,
    isFromHome: true,
  },
];

function LandingPage(props) {
  const history = useHistory();

  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [loader, setLoader] = React.useState(props.isLoading);
  const [data, setData] = React.useState("");

  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  useEffect(() => {
    setLoader(true);
    axios
      .get(`https://chameleonm2m.com/api/web-home-list`)
      .then((result) => {
        if (result.data.status) {
          setLoader(false);
          setData(result.data.data);
        } else setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.log("here in error");
        return error;
      });
  }, []);

  useEffect(() => {
    setLoader(props.isLoading);
  }, [props.isLoading]);

  return (
    <>
      {loader && <Spinner />}
      <div
        style={{
          visibility: loader == true ? "hidden" : "visible",
        }}
      >
        <ExamplesNavbar />
        <div className="wrapper homepage">
          <Carousel />

          <div className="section section-team">
            <Container className="ourproducts" fluid={true}>
              <h3 className="title"> OUR PRODUCTS</h3>
              <div className="team">
                <Row>
                  <Col md="12">
                    <ProductSlider products={sliderContent} />
                  </Col>
                </Row>
              </div>
            </Container>
          </div>

          <div className="getourapp">
            <h2>chameleon service app</h2>
            <h3> Try It Out</h3>
            <img
              className="d-lg-none d-xl-none d-block mb-5"
              src={require("assets/iphone.png")}
              alt=""
            />
            <div className="btns">
              <a
                href="https://apps.apple.com/us/app/chameleon-service-app/id1481044874"
                target="_blank"
              >
                <img src={require("assets/btn_app_store.png")} alt="" />
              </a>
              <a
                href=" https://play.google.com/store/apps/details?id=com.chameleonm2m"
                target="_blank"
              >
                <img src={require("assets/btn_google_play.png")} alt="" />
              </a>
            </div>
          </div>

          <div className="aboutus">
            <h2>ABOUT CHAMELEON</h2>
            <h3>{data?.about_us_title}</h3>

            <p>{data?.about_us_description}</p>

            {/* <ul>
              <li>
                A Key Manager has been co-funded in this company by the European
                Revgional Development
              </li>
              <li>
                Fund and Enterprise Ireland under the Border, Midland and
                Western Regional Operation Programme 2014-2020.
              </li>
              <li>
                Aims : To recruit a Key Manager that will bring skills into the
                company that are critical to future growth.
              </li>
              <li>
                Results : The Key Manager commenced employment and is
                contributing to improvements in company productivity and
                efficiencies.
              </li>
            </ul>
         */}
          </div>

          <div className="latestnews">
            <h1>LATEST NEWS</h1>
            <span>{data?.news_blod_description}</span>
            <p className="my-3">{data?.date_venue_sentence}</p>

            <div className="box">
              <div className="side1">
                <h5 className="font-weight-bold">Date</h5>
                <h5>{data?.date}</h5>
              </div>
              <div className="side2">
                <h5 className="font-weight-bold">Venue</h5>
                <h5>{data?.venue}</h5>
              </div>
            </div>

            <div className="morecontent">
              <h4>{data?.news_bold_title}</h4>

              <p>{data?.news_description}</p>

              <p>
                If you need further information, please click{" "}
                <a href="/contact">here</a> for our main{" "}
                <a href="/contact">contact page.</a>
              </p>
            </div>
          </div>

          <DefaultFooter />
        </div>
      </div>
      {/* )} */}
    </>
  );
}

// export default LandingPage;

const mapStateToProps = (state) => {
  return {
    isLoading: state.loader.isLoading,
  };
};

export default connect(mapStateToProps, {})(LandingPage);
