import React from "react";

// const productsDetail = () => {
const products_Arr = [
  // FIRST PRODUCT (1)
  {
    name: "Easy Colour",
    productid: 1,
    points: ["Economical", "Innovative", "Technical Data", "Video", "Download"],
    image: require("product_images/Easy_Colour.png"),
    points_in_detail: [
      {
        name: "whyproduct",
        subname1: "Economical",
        features1: [
          "Entry Level shaker with very competitive pricing",
          "Low Cost of ownership.",
          "Suitable for small-medium sized paint shops",
          "Simple user “Easy” operation",
          "3 Litre canisters",
          "12,16,20, & 24 canister configurations",
        ],
        subname2: "Innovative",
        features2: [
          "Patented Smart Gauges® provide exceptional accuracy with a single pump (1/384 fl oz, 0.01mls.)",
          "Unique Auto-lock® valve frees both hands for easy dispensing",
          "ETP seals available for use with Solvent based colourants",
          "Gauges in 1/48us (0.616ml), 1/48usm (0.65ml) or ml (1ml) - Other gauges on request.",
          "Simple to operate and service",
          "3 oz. (100ml) pumps for fast accurate tinting",
        ],
      },

      {
        name: "Technical Data",
        features: [
          {
            heading: "Available Gauge Increments",
            featurepoint: ["US (1/48 & 1/96)", "USM (1/48 & 1/96)", "Metric"],
          },
          {
            heading: "1 Oz = ___ml",
            featurepoint: ["US: 29.57ml", "USM: 31.23ml", "Metric: 1ml"],
          },
          {
            heading: "Dimensions H x W x D (mm)",
            featurepoint: [
              "12 Canister Countertop: 850 x 750 x 910 – 67kg",
              "16 Canister Countertop: 890 x 740 x 910 – 72kg",
              "20 Canister Countertop: 860 x 850 x 860 – 102kg",
              "24 Canister Countertop: 1000 x 1040 x 1190 – 120kg",
              "12 Canister Floorstand: 720 x 860 x 1250 – 91kg",
              "16 Canister Floorstand: 720 x 860 x 1250 – 99kg",
              "20 Canister Floorstand: 1050 x 1160 x 1180 – 124kg",
              "24 Canister Floorstand: 1050 x 1160 x 1180 – 143kg",
            ],
          },
        ],
      },
      {
        name: "Video",
        imgurl: "",
      },
      {
        name: "Download",
        features: [
          "Download Easy Colour Brochure",
          "Download Chameleon Brochure",
          "Get Technical Information",
        ],
      },
    ],
  },
  // SECOND PRODUCT (2)
  {
    name: "iMix",
    productid: 2,
    points: ["Intelligent", "Robust", "Technical Data", "Video", "Download"],
    image: require("product_images/iMix.png"),
    points_in_detail: [
      {
        name: "whyproduct",
        subname1: "Intelligent",
        features1: [
          "iMix M2M will recognize your paint can and automatically select the:",
          "Fastest Mixing Speed (RPM)",
          "Optimal Mixing Time",
          "Best Clamping Pressure",
          "Soft-Start reduces colourant overhang on paint lids",
          "iMix allows parameters changes to speed, time, and pressure to be made at the machine",
        ],
        subname2: "Robust",
        features2: [
          "Standard Electronic system with simple controls",
          "Many mechanical components common with iMix M2M",
          "Large door and sliding lower tray mean easier loading of large heavy cans",
          "Suitable for small-medium sized paint shops",
        ],
      },

      {
        name: "Technical Data",
        features: [
          {
            heading: "Mixing action",
            featurepoint: ["Gyroscopic"],
          },
          {
            heading: "Mixing capacity",
            featurepoint: ["35 kg"],
          },
          {
            heading: "Mixing Speeds",
            featurepoint: ["3 Automatically selected speeds based on can size"],
          },
          {
            heading: "Motor Power",
            featurepoint: ["0.9 kW with frequency inverter"],
          },
          {
            heading: "Mixing range - container dimensions height",
            featurepoint: ["85 - 420 mm"],
          },
          {
            heading: "Mixing range - container dimensions diameter",
            featurepoint: ["360 mm"],
          },

          {
            heading: "Mixing range - container types",
            featurepoint: ["Metal & Plastic including oval"],
          },
          {
            heading: "Clamping system",
            featurepoint: ["Automatic - pressure changes based on can size"],
          },
          {
            heading: "Display / Controls",
            featurepoint: ["LCD Display with push buttons"],
          },
          {
            heading: "Timer - Minute timer",
            featurepoint: [
              "Automatic time selection with +30s increases option during mix",
            ],
          },
          {
            heading: "Programmable",
            featurepoint: ["Yes"],
          },
          {
            heading: "Mixing Direction - bi directional",
            featurepoint: ["No"],
          },
          {
            heading: "Soft-start and speed (gear) change",
            featurepoint: ["Yes"],
          },
          {
            heading: "Languages",
            featurepoint: ["Yes"],
          },
          {
            heading: "Door",
            featurepoint: [
              "Large Perspex swinging door - fit right or left side.",
            ],
          },
          {
            heading: "Dimensions H x W x D (mm)",
            featurepoint: [
              "1100 x 800 x 860 – 180k: Machine Dimensions",
              "1100 x 800 x 860 – 200kg: Shipping Dimensions",
            ],
          },
        ],
      },
      {
        name: "Video",
        imgurl: "",
      },
      {
        name: "Download",
        features: [
          "Download iMix Brochure",
          "Download Chameleon Brochure",
          "Get Technical Information",
        ],
      },
    ],
  },

  // THIRD PRODUCT (3)
  {
    name: "iMix M2M",
    productid: 3,
    points: [
      "Intelligent",
      "Competitive",
      "Technical Data",
      "Video",
      "Download",
    ],
    image: require("product_images/iMix_m2m.png"),
    points_in_detail: [
      {
        name: "whyproduct",
        subname1: "Intelligent",
        features1: [
          "iMix M2M will recognize your paint can and automatically select the:",
          "Fastest Mixing Speed (RPM)",
          "Optimal Mixing Time",
          "Best Clamping Pressure",
          "iMix will carry out self-cleaning at regular intervals.",
          " “Safe Mode” detects Off-Centre, or Multiple cans, and adjusts mixing speed and time accordingly",
          "Soft-Start reduces colourant overhang on paint lids",
          "High Definition screen allows parameters changes to speed, time, and pressure to be made at the machine",
          "iMix M2M guides the user towards correct operation and provides Onboard diagnostics",
          "IoT connectivity",
        ],
        subname2: "Competitive",
        features2: [
          "iMix M2M provides the machines manager with:",
          "Predictive Maintenance – Reducing machine downtime",
          "Turnover Data – The number and type of cans mixed over the mixer’s life",
          "Faster clamping and un-clamping reduce time wasted between mixes",
          "Large Cradle opening allows mixing of larger paint cans",
          "Quite mixing operation makes iMix M2M suitable for P.O.S. shops",
          "Large door and sliding lower tray mean easier loading of large heavy cans",
          "Suitable for Paints, Plaster Paints, & Ink mixing facilities",
        ],
      },

      {
        name: "Technical Data",
        features: [
          {
            heading: "Mixing action",
            featurepoint: ["Gyroscopic"],
          },
          {
            heading: "Mixing capacity",
            featurepoint: ["40 kg"],
          },
          {
            heading: "Mixing Speeds",
            featurepoint: ["3 Automatically selected speeds based on can size"],
          },
          {
            heading: "Motor Power",
            featurepoint: ["1.0 kW with frequency inverter"],
          },
          {
            heading: "Mixing range - container dimensions height",
            featurepoint: ["85 - 480 mm"],
          },
          {
            heading: "Mixing range - container dimensions diameter",
            featurepoint: ["370 mm"],
          },

          {
            heading: "Mixing range - container types",
            featurepoint: ["Metal & Plastic including oval"],
          },
          {
            heading: "Clamping system",
            featurepoint: ["Automatic - pressure changes based on can size"],
          },
          {
            heading: "Display / Controls",
            featurepoint: ["HD Display with touch buttons"],
          },
          {
            heading: "Timer - Minute timer",
            featurepoint: [
              "Automatic time selection with 6 extra 'special times' ",
            ],
          },
          {
            heading: "Programmable",
            featurepoint: ["Yes"],
          },
          {
            heading: "Mixing Direction - bi directional",
            featurepoint: ["No"],
          },
          {
            heading: "Soft-start and speed (gear) change",
            featurepoint: ["Yes"],
          },
          {
            heading: "Languages",
            featurepoint: ["Yes"],
          },
          {
            heading: "Door",
            featurepoint: [
              "Large Perspex swinging door - fit right or left side.",
            ],
          },
          {
            heading: "Dimensions H x W x D (mm)",
            featurepoint: [
              "1100 x 800 x 860 – 180k: Machine Dimensions",
              "1100 x 800 x 860 – 200kg: Shipping Dimensions",
            ],
          },
        ],
      },
      {
        name: "Video",
        imgurl: "",
      },
      {
        name: "Download",
        features: [
          "Download iMix M2M Brochure",
          "Download Chameleon Brochure",
          "Get Technical Information",
        ],
      },
    ],
  },

  // FOURTH PRODUCT (4)
  {
    name: "iMix M2M Professional",
    productid: 4,
    points: [
      "Intelligent",
      "Competitive",
      "Technical Data",
      "Video",
      "Download",
    ],
    image: require("product_images/iMix_m2m_Proffessional.png"),
    points_in_detail: [
      {
        name: "whyproduct",
        subname1: "Intelligent",
        features1: [
          "iMix M2M will recognize your paint can and automatically select the:",
          "Fastest Mixing Speed (RPM)",
          "Optimal Mixing Time",
          "Best Clamping Pressure",
          "iMix will carry out self-cleaning at regular intervals.",
          " “Safe Mode” detects Off-Centre, or Multiple cans, and adjusts mixing speed and time accordingly",
          "Soft-Start reduces colourant overhang on paint lids",
          "High Definition screen allows parameters changes to speed, time, and pressure to be made at the machine",
          "iMix M2M guides the user towards correct operation and provides Onboard diagnostics",
          "IoT connectivity",
        ],
        subname2: "Competitive",
        features2: [
          "iMix M2M provides the machines manager with:",
          "Predictive Maintenance – Reducing machine downtime",
          "Turnover Data – The number and type of cans mixed over the mixer’s life",
          "Faster clamping and un-clamping reduce time wasted between mixes",
          "Large Cradle opening allows mixing of larger paint cans",
          "Quite mixing operation makes iMix M2M suitable for P.O.S. shops",
          "Large door and sliding lower tray mean easier loading of large heavy cans",
          "Suitable for Paints, Plaster Paints, & Ink mixing facilities",
        ],
      },
      {
        name: "Technical Data",
        features: [
          {
            heading: "Mixing action",
            featurepoint: ["Gyroscopic"],
          },
          {
            heading: "Mixing capacity",
            featurepoint: ["40 kg"],
          },
          {
            heading: "Mixing Speeds",
            featurepoint: ["3 Automatically selected speeds based on can size"],
          },
          {
            heading: "Motor Power",
            featurepoint: ["1.0 kW with frequency inverter"],
          },
          {
            heading: "Mixing range - container dimensions height",
            featurepoint: ["85 - 480 mm"],
          },
          {
            heading: "Mixing range - container dimensions diameter",
            featurepoint: ["370 mm"],
          },

          {
            heading: "Mixing range - container types",
            featurepoint: ["Metal & Plastic including oval"],
          },
          {
            heading: "Clamping system",
            featurepoint: ["Automatic - pressure changes based on can size"],
          },
          {
            heading: "Display / Controls",
            featurepoint: ["HD Display with touch buttons"],
          },
          {
            heading: "Timer - Minute timer",
            featurepoint: [
              "Automatic time selection with 6 extra 'special times' ",
            ],
          },
          {
            heading: "Programmable",
            featurepoint: ["Yes"],
          },
          {
            heading: "Mixing Direction - bi directional",
            featurepoint: ["No"],
          },
          {
            heading: "Soft-start and speed (gear) change",
            featurepoint: ["Yes"],
          },
          {
            heading: "Languages",
            featurepoint: ["Yes"],
          },
          {
            heading: "Door",
            featurepoint: [
              "Large Perspex swinging door - fit right or left side.",
            ],
          },
          {
            heading: "Dimensions H x W x D (mm)",
            featurepoint: [
              "1100 x 800 x 860 – 180k: Machine Dimensions",
              "1100 x 800 x 860 – 200kg: Shipping Dimensions",
            ],
          },
        ],
      },
      {
        name: "Video",
        imgurl: "",
      },

      {
        name: "Download",
        features: [
          "Download iMix M2M Brochure",
          "Download Chameleon Brochure",
          "Get Technical Information",
        ],
      },
    ],
  },

  // FIFTH PRODUCT (5)
  {
    name: "Harmony Professional",
    productid: 5,
    points: ["Intelligent", "Compact", "Versatile", "Technical Data"],
    image: require("assets/harmony/harmony.png"),
    points_in_detail: [
      {
        name: "Technical Data",
        features: [
          {
            heading: "Mixing action",
            featurepoint: [
              "Vibrational - up to 580 RPM *** (see safety notice below) ***",
            ],
          },
          {
            heading: "Mixing capacity",
            featurepoint: ["40 kg"],
          },
          {
            heading: "Mixing Speeds",
            featurepoint: ["Single Speed"],
          },
          {
            heading: "Motor Power",
            featurepoint: ["0,75 kW Motor"],
          },
          {
            heading: "Mixing range - container dimensions height",
            featurepoint: ["50 - 400 mm"],
          },
          {
            heading: "Mixing range - container dimensions diameter",
            featurepoint: ["360 mm"],
          },

          {
            heading: "Mixing range - container types",
            featurepoint: ["Metal & Plastic including oval"],
          },
          {
            heading: "Clamping system",
            featurepoint: ["Electro-Mechanical with manual adjustability"],
          },
          {
            heading: "Display / Controls",
            featurepoint: ["Push Button"],
          },
          {
            heading: "Timer - Minute timer",
            featurepoint: ["3 Preset Shake Times "],
          },
          {
            heading: "Programmable",
            featurepoint: ["No"],
          },
          {
            heading: "Mixing Direction - bi directional",
            featurepoint: ["No"],
          },
          {
            heading: "Soft-start and speed (gear) change",
            featurepoint: ["No"],
          },
          {
            heading: "Languages",
            featurepoint: ["No"],
          },
          {
            heading: "Door",
            featurepoint: [
              "Large Perspex swinging door - fit right or left side.",
            ],
          },
          {
            heading: "Dimensions H x W x D (mm)",
            featurepoint: [
              "1170 x 670 x 720 – Machine Dimensions",
              "1170 x 670 x 720 – Shipping Dimensions",
            ],
          },
        ],
      },
      {
        name: "whyproduct",
        subname1: "Compact",
        features1: [
          "Standard Electronic system with simple controls",
          "Many mechanical components common with iMix M2M",
          "Large door and sliding lower tray mean easier loading of large heavy cans",
          "Suitable for small-medium sized paint shops",
        ],
        subname2: "Versatile",
        features2: [
          "Standard Electronic system with single shake speed.",
          "3 shake time options",
          "Unique clamping system eliminates can leaks and crushing",
          "Simple to operate and service",
        ],
      },
      {
        name: "Video",
        imgurl: "",
      },

      {
        name: "Download",
        features: [
          "Download Harmony Professional Brochure",
          "Download Chameleon Brochure",
          "Get Technical Information",
        ],
      },
    ],
  },

  // SIXTH PRODUCT (6)
  {
    name: "RGM Professional",
    productid: 6,
    points: ["Intelligent", "Compact", "Versatile", "Technical Data"],
    image: require("product_images/RGM.png"),
    points_in_detail: [
      {
        name: "Technical Data",
        features: [
          {
            heading: "Mixing action",
            featurepoint: [
              "Vibrational - up to 580 RPM *** (see safety notice below) ***",
            ],
          },
          {
            heading: "Mixing capacity",
            featurepoint: ["40 kg"],
          },
          {
            heading: "Mixing Speeds",
            featurepoint: ["Single Speed"],
          },
          {
            heading: "Motor Power",
            featurepoint: ["0,75 kW Motor"],
          },
          {
            heading: "Mixing range - container dimensions height",
            featurepoint: ["50 - 400 mm"],
          },
          {
            heading: "Mixing range - container dimensions diameter",
            featurepoint: ["360 mm"],
          },

          {
            heading: "Mixing range - container types",
            featurepoint: ["Metal & Plastic including oval"],
          },
          {
            heading: "Clamping system",
            featurepoint: ["Electro-Mechanical with manual adjustability"],
          },
          {
            heading: "Display / Controls",
            featurepoint: ["Push Button"],
          },
          {
            heading: "Timer - Minute timer",
            featurepoint: ["3 Preset Shake Times "],
          },
          {
            heading: "Programmable",
            featurepoint: ["No"],
          },
          {
            heading: "Mixing Direction - bi directional",
            featurepoint: ["No"],
          },
          {
            heading: "Soft-start and speed (gear) change",
            featurepoint: ["No"],
          },
          {
            heading: "Languages",
            featurepoint: ["No"],
          },
          {
            heading: "Door",
            featurepoint: [
              "Large Perspex swinging door - fit right or left side.",
            ],
          },
          {
            heading: "Dimensions H x W x D (mm)",
            featurepoint: [
              "1170 x 670 x 720 – Machine Dimensions",
              "1170 x 670 x 720 – Shipping Dimensions",
            ],
          },
        ],
      },

      {
        name: "whyproduct",
        subname1: "Compact",
        features1: [
          "Standard Electronic system with simple controls",
          "Many mechanical components common with iMix M2M",
          "Large door and sliding lower tray mean easier loading of large heavy cans",
          "Suitable for small-medium sized paint shops",
        ],
        subname2: "Versatile",
        features2: [
          "Standard Electronic system with single shake speed.",
          "3 shake time options",
          "Unique clamping system eliminates can leaks and crushing",
          "Simple to operate and service",
        ],
      },
      {
        name: "Video",
        imgurl: "",
      },

      {
        name: "Download",
        features: [
          "Download RGM Professional Brochure",
          "Download Chameleon Brochure",
          "Get Technical Information",
        ],
      },
    ],
  },

  // SEVENTH PRODUCT (7)
  {
    name: "eShake",
    productid: 7,
    points: ["Economical", "Robust", "Technical Data", "Video", "Download"],
    image: require("product_images/eShake.png"),
    points_in_detail: [
      {
        name: "whyproduct",
        subname1: "Economical",
        features1: [
          "Entry Level shaker with very competitive pricing",
          "Low Cost of ownership.",
          "Suitable for small-medium sized paint shops",
          "Many mechanical components common with iShake",
          "eShake’s Driveshaft generates 80% more centrifugal force than equivalent shakers",
          "12,16,20, & 24 canister configurations",
        ],
        subname2: "Robust",
        features2: [
          "Standard Electronic system with single shake speed.",
          "3 shake time options",
          "Unique clamping system eliminates can leaks and crushing",
          "Simple to operate and service",
        ],
      },

      {
        name: "Technical Data",
        features: [
          {
            heading: "Mixing action",
            featurepoint: [
              "Vibrational - up to 580 RPM *** (see safety notice below) ***",
            ],
          },
          {
            heading: "Mixing capacity",
            featurepoint: ["40 kg"],
          },
          {
            heading: "Mixing Speeds",
            featurepoint: ["Single Speed"],
          },
          {
            heading: "Motor Power",
            featurepoint: ["0,75 kW Motor"],
          },
          {
            heading: "Mixing range - container dimensions height",
            featurepoint: ["50 - 400 mm"],
          },
          {
            heading: "Mixing range - container dimensions diameter",
            featurepoint: ["360 mm"],
          },

          {
            heading: "Mixing range - container types",
            featurepoint: ["Metal & Plastic including oval"],
          },
          {
            heading: "Clamping system",
            featurepoint: ["Electro-Mechanical with manual adjustability"],
          },
          {
            heading: "Display / Controls",
            featurepoint: ["Push Button"],
          },
          {
            heading: "Timer - Minute timer",
            featurepoint: ["3 Preset Shake Times "],
          },
          {
            heading: "Programmable",
            featurepoint: ["No"],
          },
          {
            heading: "Mixing Direction - bi directional",
            featurepoint: ["No"],
          },
          {
            heading: "Soft-start and speed (gear) change",
            featurepoint: ["No"],
          },
          {
            heading: "Languages",
            featurepoint: ["No"],
          },
          {
            heading: "Door",
            featurepoint: [
              "Large Perspex swinging door - fit right or left side.",
            ],
          },
          {
            heading: "Dimensions H x W x D (mm)",
            featurepoint: [
              "1170 x 670 x 720 – Machine Dimensions",
              "1170 x 670 x 720 – Shipping Dimensions",
            ],
          },
        ],
      },

      {
        name: "Video",
        imgurl: "",
      },

      {
        name: "Download",
        features: [
          "Download eShake Brochure",
          "Download Chameleon Brochure",
          "Get Technical Information",
        ],
      },
    ],
  },

  // EIGHT PRODUCT (8)
  {
    name: "iShake",
    productid: 8,
    points: [
      "Intelligent",
      "Competitive",
      "Technical Data",
      "Video",
      "Download",
    ],
    image: require("product_images/iShake.png"),
    points_in_detail: [
      {
        name: "Technical Data",
        features: [
          {
            heading: "Mixing action",
            featurepoint: [
              "Vibrational - up to 580 RPM *** (see safety notice below) ***",
            ],
          },
          {
            heading: "Mixing capacity",
            featurepoint: ["40 kg"],
          },
          {
            heading: "Mixing Speeds",
            featurepoint: ["Single Speed"],
          },
          {
            heading: "Motor Power",
            featurepoint: ["0,75 kW Motor"],
          },
          {
            heading: "Mixing range - container dimensions height",
            featurepoint: ["50 - 400 mm"],
          },
          {
            heading: "Mixing range - container dimensions diameter",
            featurepoint: ["360 mm"],
          },

          {
            heading: "Mixing range - container types",
            featurepoint: ["Metal & Plastic including oval"],
          },
          {
            heading: "Clamping system",
            featurepoint: ["Electro-Mechanical with manual adjustability"],
          },
          {
            heading: "Display / Controls",
            featurepoint: ["Push Button"],
          },
          {
            heading: "Timer - Minute timer",
            featurepoint: ["3 Preset Shake Times "],
          },
          {
            heading: "Programmable",
            featurepoint: ["No"],
          },
          {
            heading: "Mixing Direction - bi directional",
            featurepoint: ["No"],
          },
          {
            heading: "Soft-start and speed (gear) change",
            featurepoint: ["No"],
          },
          {
            heading: "Languages",
            featurepoint: ["No"],
          },
          {
            heading: "Door",
            featurepoint: [
              "Large Perspex swinging door - fit right or left side.",
            ],
          },
          {
            heading: "Dimensions H x W x D (mm)",
            featurepoint: [
              "1170 x 670 x 720 – Machine Dimensions",
              "1170 x 670 x 720 – Shipping Dimensions",
            ],
          },
        ],
      },

      {
        name: "whyproduct",
        subname1: "Intelligent",
        features1: [
          "iShake will recognize your paint can and automatically select the:",
          "Fastest Shaking Speed (RPM)",
          "Optimal Shaking Time",
          "iShake will carry out self-cleaning at regular intervals.",
          "LED screen allows parameters changes to speed, time, and pressure to be made at the machine",
          "Onboard diagnostics",
        ],
        subname2: "Competitive",
        features2: [
          "iShake’s Driveshaft generates 80% more centrifugal force than equivalent shakers",
          "Suitable for medium-large sized paint shops",
          "Suitable for Ink mixing processes",
          "Suitable for plaster paint mixing facilities",
        ],
      },

      {
        name: "Video",
        imgurl: "",
        // features: [],
      },

      {
        name: "Download",
        features: [
          "Download iShake Brochure",
          "Download Chameleon Brochure",
          "Get Technical Information",
        ],
      },
    ],
  },

  // NINTH PRODUCT (9)
  {
    name: "iShake Professional",
    productid: 9,
    points: [
      "Intelligent",
      "Competitive",
      "Technical Data",
      "Video",
      "Download",
    ],
    image: require("assets/ishake professional/ishake_professional.png"),
    points_in_detail: [
      {
        name: "Technical Data",
        features: [
          {
            heading: "Mixing action",
            featurepoint: [
              "Vibrational - up to 580 RPM *** (see safety notice below) ***",
            ],
          },
          {
            heading: "Mixing capacity",
            featurepoint: ["40 kg"],
          },
          {
            heading: "Mixing Speeds",
            featurepoint: ["Single Speed"],
          },
          {
            heading: "Motor Power",
            featurepoint: ["0,75 kW Motor"],
          },
          {
            heading: "Mixing range - container dimensions height",
            featurepoint: ["50 - 400 mm"],
          },
          {
            heading: "Mixing range - container dimensions diameter",
            featurepoint: ["360 mm"],
          },

          {
            heading: "Mixing range - container types",
            featurepoint: ["Metal & Plastic including oval"],
          },
          {
            heading: "Clamping system",
            featurepoint: ["Electro-Mechanical with manual adjustability"],
          },
          {
            heading: "Display / Controls",
            featurepoint: ["Push Button"],
          },
          {
            heading: "Timer - Minute timer",
            featurepoint: ["3 Preset Shake Times "],
          },
          {
            heading: "Programmable",
            featurepoint: ["No"],
          },
          {
            heading: "Mixing Direction - bi directional",
            featurepoint: ["No"],
          },
          {
            heading: "Soft-start and speed (gear) change",
            featurepoint: ["No"],
          },
          {
            heading: "Languages",
            featurepoint: ["No"],
          },
          {
            heading: "Door",
            featurepoint: [
              "Large Perspex swinging door - fit right or left side.",
            ],
          },
          {
            heading: "Dimensions H x W x D (mm)",
            featurepoint: [
              "1170 x 670 x 720 – Machine Dimensions",
              "1170 x 670 x 720 – Shipping Dimensions",
            ],
          },
        ],
      },

      {
        name: "whyproduct",
        subname1: "Intelligent",
        features1: [
          "iShake will recognize your paint can and automatically select the:",
          "Fastest Shaking Speed (RPM)",
          "Optimal Shaking Time",
          "iShake will carry out self-cleaning at regular intervals.",
          "LED screen allows parameters changes to speed, time, and pressure to be made at the machine",
          "Onboard diagnostics",
        ],
        subname2: "Competitive",
        features2: [
          "Ergonomic design allows lower plate to align with roller conveyor systems",
          "iShake’s Driveshaft generates 80% more centrifugal force than equivalent shakers",
          "Suitable for Ink mixing processes",
          "Suitable for plaster paint mixing facilities",
        ],
      },

      {
        name: "Video",
        imgurl: "",
      },
      {
        name: "Download",
        features: [
          "Download iShake Professional Brochure",
          "Download Chameleon Brochure",
          "Get Technical Information",
        ],
      },
    ],
  },
];
// }

export default products_Arr;
