import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import ViewDetailFeature from "./ViewDetailFeature";

const Dummy = (props) => {
  const history = useHistory();
  const product_name = props.location.state.product_name;
  const product_type = props.location.state.product_type;
  useEffect(() => {
    history.push(`/viewDetails/${product_name}/${product_type}`, {
      product_name: props.location.state.product_name,
      // points: props.location.state.points,
      // imgurls: props.location.state.imgurls,
      product_type: props.location.state.product_type,
      // image: props.location.state.image,
    });
  }, []);
  return <div className="component"></div>;
};
export default Dummy;
