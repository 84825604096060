/**
 * Package Name :- npm i react-multi-carousel
 * Install Command :- npm i react-multi-carousel
 * Document Link :- https://www.npmjs.com/package/react-multi-carousel
 * */

import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ChevronRight } from "react-feather";
import { Link } from "react-router-dom";
import store from "redux/storeconfig/store";
import { Actionloader } from "redux/actions/index";
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 4, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};
var dataArr = [];

export default (props) => {
  const [sliderContent, setSliderContent] = useState([]);

  for (let i = 0; i < props.products.length; i++) {
    dataArr.push(
      <div key={i}>
        <div className="team-player text-center" style={{ padding: "5px" }}>
          <img
            alt="..."
            className="img-fluid"
            src={props.products[i].image}
            onLoad={() => store.dispatch(Actionloader(false))}
          ></img>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: "/products",
              state: {
                product_no: props.products[i].product_no,
              },
            }}
          >
            <div>
              {/* // OnClick={}> */}
              <div className="productslider_card">
                <span>{props.products[i].label}</span>
                <div className="right_arrow">
                  <ChevronRight size="20" color="white" />
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    );
  }

  useEffect(() => {
    setSliderContent(dataArr);
  }, [props.products]);

  return (
    <>
      <Carousel
        additionalTransfrom={0}
        arrows={window.innerWidth <= 768 ? true : false}
        autoPlay={false}
        autoPlaySpeed={4000}
        centerMode={false}
        className=""
        containerClass="container-with-dots"
        customTransition="all 1s linear"
        dotListClass=""
        draggable={false}
        focusOnSelect={false}
        infinite={false}
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={responsive}
        showDots={false}
        sliderClass=""
        slidesToSlide={2}
        swipeable
        transitionDuration={1000}
      >
        {sliderContent}
      </Carousel>
    </>
  );
};
