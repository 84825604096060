const intialState = {
  current_product: 0,
  products_list: {
    Gyroscopic_Mixers: [],
    Vibrational_Shakers: [],
    Manual_Dispensers: [],
    Legacy_Products: [],
  },
};

const getProductState = (state, action) => {
  return {
    ...state,
    current_product: action.payload,
  };
};

const Products = (state = intialState, action) => {
  switch (action.type) {
    case "GET_PRODUCTS":
      return getProductState(state, action);

    case "PRODUCTS_LIST":
      return {
        ...state,
        products_list: action.data,
      };

    default:
      return state;
  }
};
export default Products;
