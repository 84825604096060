import React, { useState } from "react";
import {
  CardBody,
  CardText,
  Row,
  Col,
  Card,
  CardDeck,
  Button,
  Table,
  CardTitle,
  CardImg,
  CardFooter,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import { getNewsList } from "../redux/actions";
import Spinner from "views/index-sections/Spinner";
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import titleImg from "../assets/news.png";
import Date from "../assets/date.svg";
import Location from "../assets/location.svg";
import parse from "html-react-parser";
import { ChevronRight } from "react-feather";

const NewsDetail = (props) => {
  const [active, setActive] = useState([]);
  const dispatch = useDispatch();
  console.log(props.location.state.details, "daata");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const item = props.location.state.details;

  const options = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: "Australia/Sydney",
    timeZoneName: "short",
  };
  //   const isLoading = useSelector((state) => state.loader.isLoading);

  return (
    <>
      <ExamplesNavbar />
      {/* {isLoading ? (
        <Spinner />
      ) : ( */}
      <div className="news mb-5" style={{ background: "none" }}>
        {/* <Col lg="4"> */}
        {/* <img
          src={item.image}
          alt="card1"
          className="newsDetailImage"
          // width={20}
          // style={{ maxWidth: "400px", maxHeight: "300px" }}
        /> */}
        {/* </Col> */}
        {/* <Col lg="8"> */}
        <CardBody>
          <CardTitle tag="h5" className="mt-0 text-blue news-body pb-3">
            <h4 className="mt-0 d-flex justify-content-between mx-2">
              {item.title}
            </h4>
            <div className="d-flex flex-column">
              <span className="d-flex mb-25">
                <img src={Date} width={12} className="mx-2" />
                <span className="text-lightShade font-weight-600 fs-12">
                  {item.news_date}
                </span>
              </span>
              <span className="d-flex">
                <img src={Location} width={12} className="mx-2" />
                <span className="text-brown font-weight-600 fs-12">
                  {item.location}
                </span>
              </span>
            </div>
          </CardTitle>
          <CardText className="fs-13">
            {item && parse(item.description)}
          </CardText>
        </CardBody>
        {/* </Col> */}

        {/* <Col lg="12" className="mt-3">
          <CardText className="fs-13">
            {item && parse(item.description)}
          </CardText>
        </Col> */}
      </div>
      {/* )} */}
      <DefaultFooter />
    </>
  );
};

export default NewsDetail;
