import React from "react";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

function LandingPage() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [iconPills, setIconPills] = React.useState("1");
  const [pills, setPills] = React.useState("1");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <div style={{ marginBottom: "140px" }}></div>
        <div className="section section-about-us top-margin">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto " md="12">
                <Card>
                  <CardHeader>
                    <Nav role="tablist" tabs>
                      <NavItem>
                        <NavLink
                          className={iconPills === "1" ? "active" : ""}
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            setIconPills("1");
                          }}
                        >
                          Why Easy Colour?
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={iconPills === "2" ? "active" : ""}
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            setIconPills("2");
                          }}
                        >
                          Innovative
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={iconPills === "3" ? "active" : ""}
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            setIconPills("3");
                          }}
                        >
                          Easy
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={iconPills === "4" ? "active" : ""}
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            setIconPills("4");
                          }}
                        >
                          Versatile
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={iconPills === "5" ? "active" : ""}
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            setIconPills("5");
                          }}
                        >
                          Technical Date
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={iconPills === "6" ? "active" : ""}
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            setIconPills("6");
                          }}
                        >
                          Video
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={iconPills === "7" ? "active" : ""}
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            setIconPills("7");
                          }}
                        >
                          Downloads
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </CardHeader>
                  <CardBody>
                    <TabContent activeTab={"iconPills" + iconPills}>
                      <TabPane tabId="iconPills1">
                        <Row>
                          <Col md="12" className="text-center">
                            <h3 style={{ color: "#2F4192" }}>
                              Easy Colour - The Smart Dispenser
                            </h3>
                          </Col>
                          <Col md="8">
                            <ul type="square">
                              <li>Innovative</li>
                              <li>Faster Easier Tinting</li>
                              <li>Versatile</li>
                              <li>Technical Data</li>
                            </ul>
                            <h5 style={{ color: "red" }}>
                              Smart Gauge as Standard!
                            </h5>
                          </Col>
                          <Col md="4">
                            <div className="text-center">
                              <img
                                alt="..."
                                src={require("assets/product_img/2--easy-colour.png")}
                                height="200px"
                              ></img>
                            </div>
                            <div className="text-center">
                              <Button
                                style={{
                                  background: "#1EC213",
                                  fontWeight: "bold",
                                }}
                                className="mr-1"
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/download.svg")}
                                  height="15px"
                                ></img>{" "}
                                &nbsp; Download Brochure
                              </Button>
                              <Button
                                className="btn-default"
                                outline
                                type="button"
                                style={{ color: "#2F4192" }}
                              >
                                Download All Products Brochure
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="iconPills2">
                        <Row>
                          <Col md="8">
                            <h5
                              style={{ color: "#2F4192", fontWeight: "bold" }}
                            >
                              Easy colour - The Innovative Dispenser
                            </h5>
                            <ul type="square">
                              <li>
                                Patented Smart Gauges® provide exceptional
                                accuracy with a single pump (1/384 fl oz,
                                0.01mls.).
                              </li>
                              <li>
                                Unique Auto-lock® valve frees both hands for
                                easy dispensing.
                              </li>
                              <li>Pozi-Seal® eliminates valve blockages.</li>
                              <li>Programmable colorant stirring.</li>
                            </ul>
                          </Col>
                          <Col md="4">
                            <div className="text-center">
                              <img
                                alt="..."
                                src={require("assets/product_img/Easy-tab2.png")}
                                height="200px"
                              ></img>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="iconPills3">
                        <Row>
                          <Col md="8">
                            <h5
                              style={{ color: "#2F4192", fontWeight: "bold" }}
                            >
                              Easy Colour - The Easy Dispenser
                            </h5>
                            <ul type="square">
                              <li>
                                3 litre canisters with Stainless Steel 3 oz.
                                (100ml) pumps for fast accurate tinting.
                              </li>
                              <li>
                                Patented Smart Gauge - minimum dispense 1/384fl
                                oz.
                              </li>
                              <li>
                                Unique Auto-lock® frees both hands for easy
                                dispensing.
                              </li>
                            </ul>
                            <h6
                              style={{ color: "#A52A2A", fontWeight: "bold" }}
                            >
                              Smart Gauge as Standard
                            </h6>
                          </Col>
                          <Col md="4">
                            <div className="text-center">
                              <img
                                alt="..."
                                src={require("assets/product_img/Easy-tab3.png")}
                                height="200px"
                              ></img>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="iconPills4">
                        <Row>
                          <Col md="8">
                            <h5
                              style={{ color: "#2F4192", fontWeight: "bold" }}
                            >
                              Easy colour - The Versatile Dispenser
                            </h5>
                            <ul type="square">
                              <li>12, 16, 20 & 24 canister configurations.</li>
                              <li>Countertop, floor stand or combi stand.</li>
                              <li>
                                Gauges in 1/48us (0.616ml), 1/48usm (0.65ml) or
                                ml (1ml). Other gauges on request.
                              </li>
                            </ul>
                          </Col>
                          <Col md="4">
                            <div className="text-center">
                              <img
                                alt="..."
                                src={require("assets/product_img/Easy-tab4.jpg")}
                                height="250px"
                              ></img>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>

                      <TabPane tabId="iconPills5">
                        <Row>
                          <Col md="12">
                            <h5
                              style={{ color: "#2F4192", fontWeight: "bold" }}
                            >
                              Easy Colour - Technical Data
                            </h5>
                            <Table borderless>
                              <thead>
                                <tr
                                  style={{
                                    background: "#EFF4F8",
                                    color: "#2F4192",
                                  }}
                                >
                                  <th>Available Gauge Increments</th>
                                  <th>1 Oz = ___ML</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <center>US (1/48 & 1/96)</center>
                                  </td>
                                  <td>29.57ml</td>
                                </tr>
                                <tr>
                                  <td>
                                    <center>USM (1/48 & 1/96)</center>
                                  </td>
                                  <td>31.23ml</td>
                                </tr>
                                <tr>
                                  <td>
                                    <center>Metric</center>
                                  </td>
                                  <td>1ml</td>
                                </tr>
                                <tr style={{ fontWeight: "bold" }}>
                                  <td>Dispenser Model</td>
                                  <td>Dimensions (Packing)</td>
                                </tr>
                                <tr>
                                  <td>12 Canister Countertop </td>
                                  <td>85 x 75x 91cms @ 67kgs</td>
                                </tr>
                                <tr>
                                  <td> 16 Canister Countertop </td>
                                  <td>89 x 74 x 91cms @ 72kgs</td>
                                </tr>
                                <tr>
                                  <td>20 Canister Countertop </td>
                                  <td>86 x 85 x 86cms @ 102kgs</td>
                                </tr>
                                <tr>
                                  <td>24 Canister Countertop </td>
                                  <td>100 x 104 x 119cms @ 120kgs</td>
                                </tr>
                                <tr>
                                  <td>12 Canister Floorstand </td>
                                  <td>72 x 86 x 125cms @ 91kgs</td>
                                </tr>
                                <tr>
                                  <td>16 Canister Floorstand </td>
                                  <td>72 x 86 x 125cms @ 99kgs</td>
                                </tr>
                                <tr>
                                  <td>20 Canister Floorstand </td>
                                  <td>105 x 116 x 118cms @ 124kgs</td>
                                </tr>
                                <tr>
                                  <td>24 Canister Floorstand </td>
                                  <td>105 x 116 x 118cms @ 143kgs</td>
                                </tr>
                              </tbody>
                            </Table>
                            <p>
                              Specifications subject to change without notice
                            </p>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="iconPills6">
                        <Row>
                          <Col md="12">
                            <div style={{ textAlign: "center" }}>
                              <h4 style={{ color: "#2F4192" }}>
                                Coming Soon :
                              </h4>
                              <p
                                style={{
                                  color: "#2F4192",
                                  fontSize: "26px",
                                  fontWeight: "bold",
                                }}
                              >
                                Easy Colour - Product Demonstration Video
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="iconPills7">
                        <Row>
                          <Col md="8">
                            <h5
                              style={{ color: "#2F4192", fontWeight: "bold" }}
                            >
                              Easy Colour Downloads
                            </h5>
                            <span>
                              Please note that you need login details to
                              download User Manuals,
                              <br /> Technical Manuals or Spare Parts
                              Information.
                            </span>
                            <ul type="square">
                              <li>
                                Please click here to request a new user name and
                                password.
                              </li>
                              <li>
                                Please click here to request a reminder of your
                                user name and password.
                              </li>
                              <li>
                                If you are restricted from downloading files
                                from the internet and would like information
                                sent to you, please contact
                                customerservice@chameleon.ie
                              </li>
                            </ul>
                          </Col>
                          <Col md="4">
                            <div className="text-center">
                              <img
                                alt="..."
                                src={require("assets/product_img/Easy-tab7.png")}
                                height="250px"
                              ></img>
                            </div>
                            <div className="text-center">
                              <Button
                                style={{
                                  background: "#1EC213",
                                  fontWeight: "bold",
                                }}
                                className="mr-1"
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/download.svg")}
                                  height="15px"
                                ></img>{" "}
                                &nbsp; Download Brochure
                              </Button>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <h5
                              style={{ color: "#272A37", fontWeight: "bold" }}
                            >
                              IMPORTANT NOTICE
                            </h5>
                            <div style={{ fontSize: "1em !important" }}>
                              <p>
                                Access to this Area is restricted to Qualified
                                Personnel who have received written approval
                                from Chameleon Colour Systems.
                              </p>
                              <p>
                                Only technicians who have received official
                                training from Chameleon may use information from
                                this area.
                              </p>
                              <p>
                                It is strictly forbidden to use, make copies or
                                in any other manner communicate or disclose
                                information from this area to any party who has
                                not received the approval of Chameleon Colour
                                Systems.
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <div className="separator separator-primary"></div>
            <Row>
              <Col className="ml-auto mr-auto " md="12">
                <Row>
                  <Col md={{ size: 12 }}>
                    <div className="socialLinks">
                      <ul>
                        <li>
                          <div
                            style={{
                              background: "#FFFFFF 0% 0% no-repeat padding-box",
                              border: "1px solid #B1BCEB",
                              borderRadius: "7px",
                              opacity: "1",
                              height: "35px",
                              width: "35px",
                              position: "relative",
                            }}
                          >
                            <img
                              className="insideDivCenter"
                              alt="..."
                              src={require("assets/social_link/del.png")}
                              height="25px"
                            ></img>
                          </div>
                        </li>
                        <li>
                          <div
                            style={{
                              background: "#FFFFFF 0% 0% no-repeat padding-box",
                              border: "1px solid #B1BCEB",
                              borderRadius: "7px",
                              opacity: "1",
                              height: "35px",
                              width: "35px",
                              position: "relative",
                            }}
                          >
                            <span
                              className="insideDivCenter"
                              style={{
                                color: "#2F4192",
                                fontSize: "20px",
                                fontWeight: "bold",
                              }}
                            >
                              D
                            </span>
                          </div>
                        </li>
                        <li>
                          <div
                            style={{
                              background: "#FFFFFF 0% 0% no-repeat padding-box",
                              border: "1px solid #B1BCEB",
                              borderRadius: "7px",
                              opacity: "1",
                              height: "35px",
                              width: "35px",
                              position: "relative",
                            }}
                          >
                            <img
                              className="insideDivCenter"
                              alt="..."
                              src={require("assets/social_link/facebook.svg")}
                              height="25px"
                            ></img>
                          </div>
                        </li>
                        <li>
                          <div
                            style={{
                              background: "#FFFFFF 0% 0% no-repeat padding-box",
                              border: "1px solid #B1BCEB",
                              borderRadius: "7px",
                              opacity: "1",
                              height: "35px",
                              width: "35px",
                              position: "relative",
                            }}
                          >
                            <img
                              className="insideDivCenter"
                              alt="..."
                              src={require("assets/social_link/google.png")}
                              height="25px"
                            ></img>
                          </div>
                        </li>
                        <li>
                          <div
                            style={{
                              background: "#FFFFFF 0% 0% no-repeat padding-box",
                              border: "1px solid #B1BCEB",
                              borderRadius: "7px",
                              opacity: "1",
                              height: "35px",
                              width: "35px",
                              position: "relative",
                            }}
                          >
                            <img
                              className="insideDivCenter"
                              alt="..."
                              src={require("assets/social_link/123.png")}
                              height="25px"
                            ></img>
                          </div>
                        </li>
                        <li>
                          <div
                            style={{
                              background: "#FFFFFF 0% 0% no-repeat padding-box",
                              border: "1px solid #B1BCEB",
                              borderRadius: "7px",
                              opacity: "1",
                              height: "35px",
                              width: "35px",
                              position: "relative",
                            }}
                          >
                            <img
                              className="insideDivCenter"
                              alt="..."
                              src={require("assets/social_link/twiter.png")}
                              height="25px"
                            ></img>
                          </div>
                        </li>
                        <li>
                          <div
                            style={{
                              background: "#FFFFFF 0% 0% no-repeat padding-box",
                              border: "1px solid #B1BCEB",
                              borderRadius: "7px",
                              opacity: "1",
                              height: "35px",
                              width: "35px",
                              position: "relative",
                            }}
                          >
                            <img
                              className="insideDivCenter"
                              alt="..."
                              src={require("assets/social_link/linkedin.png")}
                              height="25px"
                            ></img>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default LandingPage;
