//increment user
import axios from "axios";

export const product_details = (currentPage) => {
  return { type: "GET_PRODUCT_DETAILS", payload: currentPage };
};

export const productList = (currentProduct) => {
  return { type: "GET_PRODUCTS", payload: currentProduct };
};

export const Actionloader = (val) => {
  return { type: "IS_LOADING", payload: val };
};

export const getProductsList = () => {
  return (dispatch) => {
    dispatch(Actionloader(true));
    axios
      .get(`https://chameleonm2m.com/api/web-product-list`)
      .then((result) => {
        dispatch({
          type: "PRODUCTS_LIST",
          data: result.data.data,
        });
        dispatch(Actionloader(false));
      })
      .catch((error) => {
        dispatch(Actionloader(false));
        console.log("here in error");
        return error;
      });
  };
};

export const getAboutUs = () => {
  return (dispatch) => {
    dispatch(Actionloader(true));
    axios
      .get(`https://chameleonm2m.com/api/web-about-us`)
      .then((result) => {
        dispatch({
          type: "ABOUT_US",
          data: result.data.data,
        });
        dispatch(Actionloader(false));
      })
      .catch((error) => {
        dispatch(Actionloader(false));
        console.log("here in error");
        return error;
      });
  };
};

export const getContactUs = () => {
  return (dispatch) => {
    dispatch(Actionloader(true));
    axios
      .get(`https://chameleonm2m.com/api/web-contact-us`)
      .then((result) => {
        dispatch({
          type: "CONTACT_US",
          data: result.data.data,
        });
        dispatch(Actionloader(false));
      })
      .catch((error) => {
        dispatch(Actionloader(false));
        console.log("here in error");
        return error;
      });
  };
};

export const getCustomerService = () => {
  return (dispatch) => {
    dispatch(Actionloader(true));
    axios
      .get(`https://chameleonm2m.com/api/web-customer-service`)
      .then((result) => {
        dispatch({
          type: "CUST_SERVICE",
          data: result.data.data,
        });
        dispatch(Actionloader(false));
      })
      .catch((error) => {
        dispatch(Actionloader(false));
        console.log("here in error");
        return error;
      });
  };
};

export const getNewsList = () => {
  return (dispatch) => {
    dispatch(Actionloader(true));
    axios
      .get(`https://chameleonm2m.com/api/web-news-list`)
      .then((result) => {
        dispatch({
          type: "NEWS_LIST",
          data: result.data.data,
        });
        dispatch(Actionloader(false));
      })
      .catch((error) => {
        dispatch(Actionloader(false));
        console.log("here in error");
        return error;
      });
  };
};
