/*eslint-disable*/
import React from "react";
import { Link, useHistory } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
// core components

function DefaultFooter() {
  const history = useHistory();

  return (
    <>
      <footer className="footer footer-default d-none d-lg-block d-xl-block">
        <div className="d-flex flex-column p-2 align-items-center">
          {/* <Container> */}
          <div>
            <button
              onClick={() =>
                history.push("/products", {
                  product_no: 1,
                })
              }
            >
              Vibrational Shakers
            </button>
            <button
              onClick={() =>
                history.push("/products", {
                  product_no: 0,
                })
              }
            >
              Gyroscopic Mixers
            </button>
            <button
              onClick={() =>
                history.push("/products", {
                  product_no: 2,
                })
              }
            >
              Dispensers
            </button>
            <button>Other Products</button>
            <button
              onClick={() =>
                history.push("/products", {
                  product_no: 3,
                })
              }
            >
              Legacy Products
            </button>
            <button>Featured Products</button>

            {/* <h3 className="googletranslator">Google Translator</h3> */}
            {/* <div className="secondsegment">
              <button>
                English{" "}
                <img src={require("../../assets/next (12).svg")} alt="" />
              </button>
              <button className="search">
                <img
                  src={require("../../assets/searchicon/search_bottom.svg")}
                  alt=""
                />
                <span className="searchplaceholder"> Search </span>
                <span className="searchbtn"> Search </span>
              </button>
            </div> */}

            <div className="thirdsegment">
              <div className="info">
                <span>
                  <h4>Chameleon Colour Systems Ltd,</h4>
                  <p>Business Park, Weir Road, Tuam, Co. Galway,</p>
                  <p>Ireland , H54 CH79</p>
                </span>

                <span className="contactinfo">
                  <span>
                    <img
                      src={require("./../../assets/phone (11).svg")}
                      alt=""
                    />
                    <p>Tel: +353 93 25392</p>
                  </span>

                  <span>
                    <img src={require("./../../assets/icn_fax.svg")} alt="" />
                    <p>Fax: +353 93 25394</p>
                  </span>

                  <span>
                    <img src={require("./../../assets/mail (5).svg")} alt="" />
                    <p>E-mail: sales@chameleon.ie</p>
                  </span>
                </span>

                {/* <div className="fblogo">
                  <img src={require('./../../assets/fb/download (2).png')} alt="" />
                </div> */}
              </div>

              <div className="morelogo">
                <img
                  src={require("./../../assets/logo_bottom_bg/logo_bottom_bg.png")}
                  alt=""
                />
                <img
                  src={require("./../../assets/logo_ce_mark/logo_ce_mark.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        {/* </Container> */}
      </footer>

      {/* Mobile Footer  */}

      <footer className="mobile-footer footer-default d-block d-lg-none d-xl-none">
        <div className="d-flex flex-column p-2 align-items-center">
          {/* <Container> */}
          <div className="footer">
            <button
              onClick={() =>
                history.push("/products", {
                  product_no: 1,
                })
              }
            >
              Vibrational Shakers
            </button>
            <button
              onClick={() =>
                history.push("/products", {
                  product_no: 0,
                })
              }
            >
              Gyroscopic Mixers
            </button>
            <button
              onClick={() =>
                history.push("/products", {
                  product_no: 2,
                })
              }
            >
              Dispensers
            </button>
            <button>Other Products</button>
            <button
              onClick={() =>
                history.push("/products", {
                  product_no: 3,
                })
              }
            >
              Legacy Products
            </button>
            <button>Featured Products</button>

            <div className="thirdsegment">
              <div className="info">
                <span>
                  <h4>Chameleon Colour Systems Ltd,</h4>
                  <p>Business Park, Weir Road, Tuam, Co. Galway,</p>
                  <p>Ireland , H54 CH79</p>
                </span>

                <span className="contactinfo">
                  <span>
                    <img
                      src={require("./../../assets/phone (11).svg")}
                      alt=""
                    />
                    <p>Tel: +353 93 25392</p>
                  </span>

                  <span>
                    <img src={require("./../../assets/icn_fax.svg")} alt="" />
                    <p>Fax: +353 93 25394</p>
                  </span>

                  <span>
                    <img src={require("./../../assets/mail (5).svg")} alt="" />
                    <p>E-mail: sales@chameleon.ie</p>
                  </span>
                </span>
              </div>

              <div className="morelogo">
                <img
                  src={require("./../../assets/logo_bottom_bg/logo_bottom_bg.png")}
                  alt=""
                />
                <img
                  src={require("./../../assets/logo_ce_mark/logo_ce_mark.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        {/* </Container> */}
      </footer>

      <footer
        className="footer footer-default"
        style={{ background: "#000000" }}
      >
        <Container>
          <Row>
            <Col className="ml-auto mr-auto " md="12">
              <div style={{ color: "#fff" }} className="text-center">
                All Rights Reserved.
                {/* © Copyright */}
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default DefaultFooter;
