import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";
import store from "redux/storeconfig/store";
import { Actionloader } from "redux/actions/index";
import { ChevronLeft, ChevronRight } from "react-feather";
// core components

const items = [
  {
    src: require("assets/bg/bg.png"),
    altText: "",
    caption:
      "Chameleon Colour Systems specialise in the design, development and manufacturing of paint mixing and tinting equipment. Made in Ireland, our paint shakers, mixers and dispensers are amongst the top in the global paint and chemical industries.",
    mytext: "WHO WE ARE",
    btn: "View More",
  },
  {
    src: require("assets/chameleon_banner_2.jpg"),
    altText: "",
    caption: "0",
    mytext: "",
    btn: "",
  },
  {
    src: require("assets/chameleon_banner_3.jpg"),
    altText: "",
    caption: "0",
    mytext: "",
    btn: "",
  },
  {
    src: require("assets/chameleon_banner_4.jpg"),
    altText: "",
    caption: "0",
    mytext: "",
    btn: "",
  },
];

function CarouselSection() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>
      <Container fluid={true}>
        <div className="title">{/* <h4>Carousel</h4> */}</div>
        <Row className="justify-content-center">
          <Col lg="12" md="12" className="p-0">
            <Carousel
              activeIndex={activeIndex}
              next={next}
              previous={previous}
              enableTouch="true"
              ride="carousel"
              delay={0}
              interval={6000}
              pause={false}
            >
              <CarouselIndicators
                items={items}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
              />
              {items.map((item) => {
                return (
                  <CarouselItem
                    onExiting={onExiting}
                    onExited={onExited}
                    key={item.src}
                  >
                    <div className="firstsection">
                      <img src={item.src} alt={item.altText} />
                      {item.caption !== "0" ? (
                        <div className="whoweare ">
                          <div className="content">
                            <h3>{item.mytext}</h3>
                            <p className="para">{item.caption}</p>

                            <Link to="/about">
                              <button>{item.btn}</button>
                            </Link>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </CarouselItem>
                );
              })}
              <a
                className="carousel-control-prev d-none d-md-flex"
                data-slide="prev"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  previous();
                }}
                role="button"
              >
                {/* <i className="now-ui-icons arrows-1_minimal-left"></i> */}
                <ChevronLeft
                  className="now-ui-icons arrows-1_minimal-right p-2"
                  size={40}
                />
              </a>
              <a
                className="carousel-control-next d-none d-md-flex"
                data-slide="next"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  next();
                }}
                role="button"
              >
                <ChevronRight
                  className="now-ui-icons arrows-1_minimal-right p-2"
                  size={40}
                />
                {/* <i className="now-ui-icons arrows-1_minimal-right"></i> */}
              </a>
            </Carousel>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CarouselSection;
